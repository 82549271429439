import { returnApiCallPromise } from './ApiContent';


export function UserLogin(loginObject) {
    let subURL = "login";
    return (dispatch) => {
        returnApiCallPromise("POST", subURL, loginObject)
            .then(response => { 
                dispatch({
                    type: 'LOGIN_SUCCESS',
                    data: response.data.data
                })
            }).catch(error => {
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                }
                dispatch({
                    type: 'LOGIN_FAILURE',
                    data: errorMessage
                })
            })
    }
    return (dispatch) => {
        
        dispatch({ type: 'LOGIN_SUCCESS' });
    }
}
export function UserLogout() {
   
    return (dispatch) => {
        dispatch({ type: 'LOG_OUT' });
        /* returnApiCallPromise(global.GET, patameter)
            .then(response => {               
                dispatch({
                    type: 'MEMBERSHIP_FETCH_SUCCESS',
                    data: response.data
                })
            }).catch(error => {
                dispatch({
                    type: 'MEMBERSHIP_FETCH_FAILURE',
                    data: {
                        message: error
                    }
                })
            }) */
    }
}