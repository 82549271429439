import { returnApiCallPromise } from './ApiContent';
export function getTeams(ID) {
   
    let patameter = "team";
    if(ID){
        patameter = "team?teamTypeId="+ID;
    }
    return (dispatch) => {
        dispatch({ type: 'FETCHING_TEAMS' });
        returnApiCallPromise("GET", patameter)
            .then(response => {           
                dispatch({
                    type: 'TEAMS_SUCCESS',
                    data: response.data.data
                })
            }).catch(error => {
                let errorMessage = error;
                if(error.response){
                    
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                }
                
                dispatch({
                    type: 'TEAMS_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function getTeamTypes() {
   
    let patameter = "team/team-type";
    return (dispatch) => {
        dispatch({ type: 'FETCHING_TEAM_TYPE' });
        returnApiCallPromise("GET", patameter)
            .then(response => {           
                dispatch({
                    type: 'TEAM_TYPE_SUCCESS',
                    data: response.data.data
                })
            }).catch(error => {
                let errorMessage = error;
                if(error.response){
                    
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                }
                
                dispatch({
                    type: 'TEAM_TYPE_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function getUnAssigned() {
   
    let patameter = "unassigned-staff-vehicle";
    return (dispatch) => {
        dispatch({ type: 'FETCHING_UNASSIGNED' });
        returnApiCallPromise("GET", patameter)
            .then(response => {            
                dispatch({
                    type: 'UNASSIGNED_SUCCESS',
                    data: response.data.data
                })
            }).catch(error => {
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                }
                dispatch({
                    type: 'UNASSIGNED_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function UpdateTeam(UpdateObject, TeamID) {
   
    let subURL = "team/"+TeamID;
   
    return (dispatch) => {
        returnApiCallPromise("PUT", subURL, UpdateObject)
            .then(response => { 
                if(response.data.error){
                    dispatch({
                        type: 'TEAM_UPDATE_FAILURE',
                        data: response.data.message
                    })
                }else{
                    dispatch({
                        type: 'TEAM_UPDATE_SUCCESS',
                        data: response.data
                    })
                }    
               /*  dispatch({
                    type: 'STAFF_UPDATE_SUCCESS',
                    data: response.data
                }) */
            }).catch(error => { 
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                }
                dispatch({
                    type: 'TEAM_UPDATE_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function DeleteTeam(TeamID) {
   
    let subURL = "team/"+TeamID;
    
    return (dispatch) => {
        returnApiCallPromise("DELETE", subURL, null)
            .then(response => {
                if(response.data.error){
                    dispatch({
                        type: 'TEAM_DELETE_FAILURE',
                        data: response.data.message
                    })
                }else{
                    dispatch({
                        type: 'TEAM_DELETE_SUCCESS',
                        data: response.data
                    })
                } 
               /*  dispatch({
                    type: 'TEAM_DELETE_SUCCESS',
                    data: response.data
                }) */
            }).catch(error => { 
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                }
                dispatch({
                    type: 'TEAM_DELETE_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function AddTeam(AddObject) {
   console.log("in")
    let subURL = "team/";
    return (dispatch) => {
        returnApiCallPromise("POST", subURL, AddObject)
            .then(response => { 
                if(response.data.error){
                    dispatch({
                        type: 'TEAM_ADD_FAILURE',
                        data: response.data.message
                    })
                }else{
                    dispatch({
                        type: 'TEAM_ADD_SUCCESS',
                        data: response.data
                    })
                } 
               /*  dispatch({
                    type: 'TEAM_ADD_SUCCESS',
                    data: response.data
                }) */
            }).catch(error => { 
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                }
                dispatch({
                    type: 'TEAM_ADD_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function GetTeamLeaves(filterObj) {
    let patameter = "team/leaves";
    if(filterObj){
        patameter = `team/leaves?`+
        (filterObj.StartDate ? `startDate=`+filterObj.StartDate : "startDate=")+
        (filterObj.EndDate ? `&endDate=`+filterObj.EndDate : "")+
        (filterObj.TeamID ? `&teamId=`+filterObj.TeamID : "");
    }
    return (dispatch) => {
        dispatch({ type: 'FETCHING_TEAM_LEAVE' });
        returnApiCallPromise("GET", patameter)
            .then(response => {           
                dispatch({
                    type: 'TEAM_LEAVE_SUCCESS',
                    data: response.data.data
                })
            }).catch(error => {
                let errorMessage = error;
                if(error.response){
                    
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                }
                
                dispatch({
                    type: 'TEAM_LEAVE_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function AddTeamLeaves(TeamID, AddObject) {
     let subURL = "team/"+TeamID+"/leaves";
     return (dispatch) => {
         returnApiCallPromise("POST", subURL, AddObject)
             .then(response => { 
                 if(response.data.error){
                     dispatch({
                         type: 'ADD_LEAVE_FAILURE',
                         data: response.data.message
                     })
                 }else{
                     dispatch({
                         type: 'ADD_LEAVE_SUCCESS',
                         data: response.data
                     })
                 } 
             }).catch(error => { 
                 let errorMessage = error;
                 if(error.response){
                     if(error.response.data.error){
                         errorMessage = error.response.data;
                     }
                 }
                 dispatch({
                     type: 'ADD_LEAVE_FAILURE',
                     data: errorMessage
                 })
             })
     }
 }
export function DeleteTeamLeaves(LeaveID) {
   
    let subURL = "team/leaves/"+LeaveID;
    
    return (dispatch) => {
        returnApiCallPromise("DELETE", subURL, null)
            .then(response => {
                if(response.data.error){
                    dispatch({
                        type: 'TEAM_LEAVE_DELETE_FAILURE',
                        data: response.data.message
                    })
                }else{
                    dispatch({
                        type: 'TEAM_LEAVE_DELETE_SUCCESS',
                        data: response.data
                    })
                } 
            }).catch(error => { 
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                }
                dispatch({
                    type: 'TEAM_LEAVE_DELETE_FAILURE',
                    data: errorMessage
                })
            })
    }
}