import React, { useState, useEffect, Component } from 'react';
import {
  Grid, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle, makeStyles,
  Switch, FormControlLabel, IconButton, DeleteIcon,
  EditIcon, _, swal, toast, useSelector, useDispatch
} from '../../utilities/PackagesList';
import {
  PageTitle, CustomToolbar, AlertDialog,
  CustomModel, InputField, Dropdown,
  Buttons, LoadingOverlay, PasswordField
} from "../../utilities/ComponentsList";
import { isEmptyObj } from "../../utilities/AppFunctions";
import { AddStaff} from './../../api/Staff';
import IMLocalize from 'utilities/IMLocalize';
const useStyles = makeStyles(theme => ({
  dialogTitle: {

  }
}))
const CreateStaffs = (props) => {
  const dispatch = useDispatch();
  const createState = useSelector((state) => state.AddStaffReducer);
  const classes = useStyles();
  const [Addvalues, setAddValues] = useState({
    EmpID: '',
    FName: '',
    LName: '',
    Email: '',
    Phone: '',
    StaffTypeSelected: '',
    StaffType: '',
    Password:'',
    Status: true,
    showPassword:false
  });
  const [Adderrors, setAddErrors] = useState({});
  /* useEffect(() => {
    setAddValues({
      ...Addvalues,
      modelState: props.Status
    });
  }, []) */
 
  let staffTypeList = props.parentState.StaffTypes ? props.parentState.StaffTypes : [];
  console.log(JSON.stringify(createState))
  if (createState.Success && !isEmptyObj(createState.AddingResponse)) {

    toast.success('Added Successfully!', {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: false,
    });
    props.parentClass.AddedStaff()
  } else if (createState.Failure && !isEmptyObj(createState.AddingResponse)) {
    toast.error(createState.AddingResponse.message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: false,
    });
  }
  const handleChange = name => event => {
    let targetValue = event.target.value;

    /* if (name === 'Status') {
      targetValue = !Addvalues.Status;
    } */
    setAddValues({ ...Addvalues, [name]: targetValue });
    setAddErrors({ ...Adderrors, [name]: targetValue ? false : true })
  };
  const submitForm = () => {
    let srrorObj = {};
    let errorStatus = false;
    Object.keys(Addvalues).map(function (k) {
      if(k==='showPassword'){
        srrorObj[k] = Addvalues[k] ? false : true;
        errorStatus = errorStatus ? true : false;
      }else{
        srrorObj[k] = Addvalues[k] ? false : true;
        errorStatus = errorStatus ? true : !Addvalues[k];
      }
    })
    console.log(JSON.stringify(srrorObj),errorStatus)
    setAddErrors(srrorObj);
    if(!errorStatus){
      let addObject = {
        empId: Addvalues.EmpID,
        firstName : Addvalues.FName,
        lastName: Addvalues.LName,
        email : Addvalues.Email,
        phone : Addvalues.Phone,
        password : Addvalues.Password,
        staffTypeId : Addvalues.StaffTypeSelected,
        roleId : 1
      }
      console.log(JSON.stringify(addObject))
      dispatch(AddStaff(addObject));
    } 
  };
  
  return (
    <Dialog
      open={props.Status}
      //onClose={()=>this.closeDialog()}
      fullWidth
      maxWidth="sm"
      disableBackdropClick={false}
      disableEscapeKeyDown={false}
    >
      <DialogTitle className={classes.dialogTitle} id="dialog-title">{props.Title}</DialogTitle>
      <DialogContent dividers>
      <Grid container spacing={4}>
        <Grid item md={12}>
          <Grid
            container
            direction="row"
          >
            <Grid item md={5}>
              <Dropdown
                label={IMLocalize().staffType}
                data={staffTypeList}
                getOptionLabel={option => (option.staffType ? option.staffType : "")}
                getOptionSelected={(option, value) => option.id === value.id}
                value={Addvalues.StaffType}
                ItemChange={(event, newValue) => {
                  setAddValues({
                    ...Addvalues,
                    StaffTypeSelected: newValue ? newValue.id : null,
                    StaffType: newValue ? newValue : null
                  });
                  setAddErrors({
                    ...Adderrors,
                    StaffTypeSelected: newValue ? false : true,
                    StaffType: newValue ? false : true
                  })
                }}
                error={Adderrors.StaffTypeSelected}
                initialName={IMLocalize().staffType}
                errorMsg={"Please choose vehicle type."}
              />
            </Grid>
            <Grid item md={1} />
            <Grid item md={5}>
              <InputField
                label={IMLocalize().employeeId}
                type={"number"}
                required={true}
                value={Addvalues.EmpID}
                changeText={handleChange('EmpID')}
                error={Adderrors.EmpID}
                initialName={IMLocalize().employeeId}
                errorMsg={"Please enter employee id."}
              />
            </Grid>
            <Grid item md={1} />
          </Grid>
        </Grid>
        <Grid item md={12}>
          <Grid
            container
            direction="row"
          >
            <Grid item md={5}>
              <InputField
                label={IMLocalize().firstName}
                type={"text"}
                required={true}
                value={Addvalues.FName}
                changeText={handleChange('FName')}
                error={Adderrors.FName}
                initialName={IMLocalize().firstName}
                errorMsg={"Please enter first name."}
              />
            </Grid>
            <Grid item md={1} />
            <Grid item md={5}>
              <InputField
                label={IMLocalize().lastName}
                type={"text"}
                required={true}
                value={Addvalues.LName}
                changeText={handleChange('LName')}
                error={Adderrors.LName}
                initialName={IMLocalize().lastName}
                errorMsg={"Please enter last name."}
              />
            </Grid>
            <Grid item md={1} />
          </Grid>

        </Grid>
        <Grid item md={12}>
          <Grid
            container
            direction="row"
          >
            <Grid item md={5}>
              <InputField
                label={IMLocalize().email}
                type={"email"}
                required={true}
                value={Addvalues.Email}
                changeText={handleChange('Email')}
                error={Adderrors.Email}
                initialName={IMLocalize().email}
                errorMsg={"Please enter email address."}
              />
            </Grid>
            <Grid item md={1} />
            <Grid item md={5}>
              <InputField
                label={IMLocalize().phone}
                type={"tel"}
                required={true}
                value={Addvalues.Phone}
                changeText={handleChange('Phone')}
                error={Adderrors.Phone}
                initialName={IMLocalize().phone}
                errorMsg={"Please enter phone number."}
              />
            </Grid>
            <Grid item md={1} />
          </Grid>

        </Grid>
        <Grid item md={12}>
          <Grid
            container
            direction="row"
          >
            <Grid item md={5}>
              <PasswordField
                label={IMLocalize().password}
                type={Addvalues.showPassword ? "text" : "password"}
                required={true}
                value={Addvalues.Password}
                showText={()=>setAddValues({ ...Addvalues, showPassword: !Addvalues.showPassword })}
                HideText={Addvalues.showPassword}
                changeText={handleChange('Password')}
                error={Adderrors.Password}
                initialName={IMLocalize().password}
                errorMsg={"Please enter password."}
              />
            </Grid>
            <Grid item md={1} />
            <Grid item md={5}/>
            <Grid item md={1} />
          </Grid>

        </Grid>
        {/*  <Grid item md={12}>
        <FormControlLabel
          value={Addvalues.Status}
          control={
            <Switch
              size="medium"

              checked={Addvalues.Status}
              onChange={handleChange('Status')}
              inputProps={{ 'aria-label': 'primary' }}
              color="primary" />
          }
          label="Activate"
          fullWidth={true}
          labelPlacement="start"
        />
      </Grid> */}
        <Buttons
            close={() => props.parentClass.CloseCreate()}
            closeText={IMLocalize().calcel}
            save={() => submitForm()}
            saveText={IMLocalize().submit}
          />
      </Grid>
       </DialogContent>
    </Dialog>
  );
}

export default CreateStaffs;