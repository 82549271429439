

export function getStaffsReducer(state = {}, action) {    
    
    switch (action.type) {
        case 'STAFFS_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                StaffList: action.data
            }
        }
        case 'STAFFS_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success:false,
                StaffList: action.data
            }
        }
        case 'FETCHING_STAFFS': {
            return {
                ...state,
                Success: false,
                Failure: false,
                StaffList: action.data
            }
        }
        default: {
            return state;
        }
    }
}
export function getStaffTypesReducer(state = {}, action) {    
    switch (action.type) {
        case 'STAFF_TYPES_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                StaffTypes: action.data
            }
        }
        case 'STAFF_TYPES_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success:false,
                StaffTypes: action.data
            }
        }
        case 'FETCHING_STAFF_TYPES': {
            return {
                ...state,
                Success: false,
                Failure: false,
                StaffTypes: action.data
            }
        }
        default: {
            return state;
        }
    }
}

export function AddStaffReducer(state = {}, action) {
    switch (action.type) {
        case 'STAFF_ADD_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                AddingResponse: action.data
            }
        }
        case 'STAFF_ADD_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success:false,
                AddingResponse: action.data,
            }
        }
        default: {
            return {
                ...state,
                Failure: false,
                Success:false,
                AddingResponse: null,
            }
        }
    }
}
export function UpdateStaffReducer(state = {}, action) {
    switch (action.type) {
        case 'STAFF_UPDATE_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                UpdateResponse: action.data
            }
        }
        case 'STAFF_UPDATE_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success: false,
                UpdateResponse: action.data,
            }
        }
        default: {
            return {
                ...state,
                Failure: false,
                Success: false,
                UpdateResponse: null,
            };
        }
    }
}
export function DeleteStaffReducer(state = {}, action) {
    
    switch (action.type) {
       
        case 'STAFF_DELETE_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                DeleteResponse: action.data
            }
        }
        case 'STAFF_DELETE_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success: false,
                DeleteResponse: action.data,
            }
        }
        default: {
            return {
                ...state,
                Failure: false,
                Success: false,
                DeleteResponse: null,
            }
        }
    }
}
