export function SendSMSReducer(state = {}, action) {
    switch (action.type) {
        case 'SEND_SMS_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                Response: action.data
            }
        }
        case 'SEND_SMS_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success: false,
                Response: action.data,
            }
        }
        default: {
            return {
                ...state,
                Failure: false,
                Success: false,
                Response: null,
            };
        }
    }
}
export function getSMSSettingsReducer(state = {}, action) {
    switch (action.type) {
        case 'SMS_SETTING': {
            return {
                ...state,
                smsSettings : action.data
            }
        }
        case 'SMS_SETTING_FAILURE': {
            return {
                ...state,
                smsSettings : []
            }
        }
        default: {
            return {
                ...state,
                smsSettings : action.data
            };
        }
    }
}