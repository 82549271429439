import React from "react";
import { TextField, Autocomplete} from "./../../utilities/PackagesList";

const Dropdown = (props) => { 
    return (
        <Autocomplete
                size="small"
                options={props.data}
                multiple={props.multiple ? props.multiple : false}
                filterSelectedOptions={props.filterSelectedOptions ? props.filterSelectedOptions : false}
                getOptionLabel={props.getOptionLabel}
                getOptionDisabled={props.getOptionDisabled}
                value={props.value}
                disableClearable={false}
                onChange={props.ItemChange}
                getOptionSelected={props.getOptionSelected}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={props.label}
                    variant="outlined"
                    fullWidth
                    placeholder={props.initialName}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={props.error}
                    helperText={props.error ? props.errorMsg : ""}
                  />
                )}
              />
    )
}
export default Dropdown;