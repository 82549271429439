import { returnApiCallPromise } from './ApiContent';
export function SendSMS(reqObject) {
    let subURL = "notification";
    return (dispatch) => {
        returnApiCallPromise("POST", subURL, reqObject, null, "sms")
            .then(response => { 
                if(response.data.error){
                    dispatch({
                        type: 'SEND_SMS_FAILURE',
                        data: response.data
                    })
                }else{
                    dispatch({
                        type: 'SEND_SMS_SUCCESS',
                        data: response.data
                    })
                }  
            }).catch(error => { 
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                } 
                dispatch({
                    type: 'SEND_SMS_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function getSMSSettings() {
    let patameter = "notification";
    return (dispatch) => {
        returnApiCallPromise("GET", patameter, null, null, "sms")
            .then(response => { 
                dispatch({
                    type: 'SMS_SETTING',
                    data: response.data.data
                })
            }).catch(error => {
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                }
                dispatch({
                    type: 'SMS_SETTING_FAILURE',
                    data: errorMessage
                })
            })
    }
}