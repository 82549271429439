import React, { useState , Component} from "react";
import { 
  Grid, CircularProgress, Typography,
  Button, Tabs, Tab, TextField, Fade,
  withRouter, classnames, connect,
  ToastContainer, toast, Avatar , 
  useSelector, useDispatch, axios,
  withStyles
 } from "../../utilities/PackagesList";
 import 
  {
    PageTitle, CustomToolbar, AlertDialog,
    CustomModel, InputField, Dropdown, 
    Buttons, LoadingOverlay
  } from "./../../utilities/ComponentsList";
 import { UserLogin } from './../../api/LoginApi';
 import { LoaderStatus} from './../../api/Loading';
 import {validateTime} from './../../utilities/AppFunctions';
 import { refreshReduxStates } from './../../api/Refresh';
 import {getSMSSettings} from './../../api/Notification';
// styles
import useStyles from "./styles";
import styles from "./stylesClass";
// logo
import logo from "./logo-round.png";
class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      userName:"",
      password:"",
      userNameError:false,
      passwordError:false,
      error:false,
      postCalls:[0]
      
    }
     

  }
  componentDidMount=()=>{
    let SnCloginTime = localStorage.getItem("SnCloginTime");
    let SnCloginRequest = localStorage.getItem("SnCloginRequest");
    if(SnCloginTime && validateTime(Date.now(),SnCloginTime) && SnCloginRequest){
      let loginObj = JSON.parse(localStorage.getItem("SnCloginRequest")); 
      localStorage.setItem("SnCloginTime",Date.now());
       this.setState({
        isLoading: true,
        postCalls :[1]
      },()=>{
        this.props.UserLogin(loginObj);
      }) 
    }
  }
  UNSAFE_componentWillReceiveProps = (props) => {
    if (this.state.postCalls[0]) {
      if (props.LoginStatus) {
          this.setState({
            isLoading: false,
          },()=>{
            let LoginDetails = props.UserDetails;
            if(LoginDetails && LoginDetails.length>0){
              localStorage.setItem("SnCloginTime",Date.now());
              sessionStorage.setItem('SnCLoginResponse', JSON.stringify(LoginDetails));
              global.CompanyID = LoginDetails[0].userInfo.companyId;
             // props.getSMSSettings();
              props.history.push('/app/vehicles');
            }else{
              toast.error('Please enter valid login credentials!', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: false,
              });
            } 
          })
          
      } else if (!props.LoginStatus) {
        this.setState({
          isLoading: false,
        },()=>{
          let LoginDetails = props.UserDetails;
          toast.error(LoginDetails.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
          });
        })
      }
    }
  }
  loginUser=()=>{
    if (this.state.userName.length>0 && this.state.password.length>0) {
      let loginObj = {
        "email" : this.state.userName,
        "password" : this.state.password,
        "userType" : "3"
    }; 
      this.setState({
        isLoading :true,
        postCalls:[1]
      },()=>{
        localStorage.setItem("SnCloginRequest",JSON.stringify(loginObj));
        this.props.UserLogin(loginObj)
      })
    } else {
      toast.error('Please enter valid login credentials!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: false,
      });
    }
  }
  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.container}>
        <div className={classes.logotypeContainer}>
          <Typography className={classes.logotypeText}>Delivery Management System</Typography>
        </div>
        <div className={classes.formContainer}>
          <div className={classes.form}>
              <React.Fragment>
              <Grid container  flexDirection="column" className={classes.logoContainer}>
              </Grid>
              <Typography color="primary">
                Login to access delivery management system 
              </Typography>
                <Fade in={this.state.error}>
                  <Typography color="secondary" className={classes.errorMessage}>
                    Please enter valid login or password!
                  </Typography>
                </Fade>
                <Grid container spacing={5} flexDirection="column">
                <Grid  item md={12}>
                <InputField
                      label="User Name"
                      type={"text"}
                      required={true}
                      value={this.state.userName}
                      KeyPress={event => event.key === "Enter" && this.loginUser()}
                      changeText={(e)=>{
                        this.setState({userName:e.target.value,userNameError:false})
                      }}
                      error={this.state.userNameError}
                      errorMsg={"Please enter user name."}
                    />
                </Grid>
                <Grid  item md={12} >
                <InputField
                      label="Password"
                      type={"password"}
                      required={true}
                      KeyPress={event => event.key === "Enter" && this.loginUser()}
                      value={this.state.password}
                      changeText={(e)=>{
                        this.setState({password:e.target.value,passwordError:false})
                      }}
                      error={this.state.passwordError}
                      errorMsg={"Please enter password."}
                    />
                    </Grid>
                    </Grid>
                <div className={classes.formButtons}>
                  {this.state.isLoading ? (
                    <CircularProgress size={26} className={classes.loginLoader} />
                  ) : (
                    <Button
                      disabled={
                        this.state.userName.length === 0 || this.state.password.length === 0
                      }
                      fullWidth={true}
                      onClick={() => this.loginUser()}
                      className={classes.buttonSuccessStyle}
                      variant="contained"
                      color="primary"
                      size="large"
                    >
                      Login
                    </Button>
                  )}
                </div>
              </React.Fragment>
            
          </div>
        </div>
      </Grid>
    )
  }
}

const mapStateToProps = (state) => {
  return{
    LoginStatus : state.LoginReducer.Status,
    UserDetails : state.LoginReducer.UserDetails
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
      UserLogin: (loginObj) => dispatch(UserLogin(loginObj)),
      getSMSSettings: () => dispatch(getSMSSettings()),
      LoaderStatus:(status) => dispatch(LoaderStatus(status)), 
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Login));
