import React, { useState } from "react";
import {
  AppBar, Toolbar, IconButton, InputBase, Menu, MenuItem, Fab, Link,
  MenuIcon, MailIcon, NotificationsIcon, AccountIcon, SearchIcon, SendIcon, ArrowBackIcon,
  classnames, Avatar, useSelector
} from "./../../utilities/PackagesList";
import logo from "./../../images/snc_logo.png";
// styles
import useStyles from "./styles";

// components
import { Badge, Typography, Button } from "../Wrappers";
import Notification from "../Notification/Notification";
import UserAvatar from "../UserAvatar/UserAvatar";
import { connect } from "./../../utilities/PackagesList";
import { UserLogout } from "./../../api/LoginApi";
// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { Dropdown } from "utilities/ComponentsList";
import { getSession, setSession } from "utilities/AppFunctions";
//import { useUserDispatch, signOut } from "../../context/UserContext";

function signOut(props) {
  localStorage.clear();
  sessionStorage.clear();
  global.reduxStore.dispatch({ type: 'LOG_OUT' });
  props.history.push("/login");
}
function Header(props) {
  var classes = useStyles();
  const LoginState = useSelector((state) => state.LoginReducer);
  let fullName = "";
  if (LoginState.Status) {
    let userDetails = LoginState.UserDetails;
    if (userDetails && userDetails.length > 0) {
      fullName = userDetails[0].userInfo.firstName + " " + userDetails[0].userInfo.lastName;
    }
  }
  // global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  //var userDispatch = useUserDispatch();
  var lnguage = getSession("language");
  // local
  var [lng, setLng] = useState(lnguage);
  var [isMailsUnread, setIsMailsUnread] = useState(true);
  var [notificationsMenu, setNotificationsMenu] = useState(null);
  var [isNotificationsUnread, setIsNotificationsUnread] = useState(true);
  var [profileMenu, setProfileMenu] = useState(null);
  var [isSearchOpen, setSearchOpen] = useState(false);
  const LANGUAGES = [
    { label: "English", code: "en" },
    { label: "Chinese", code: "zh" },
  ];
  const onChangeLang = (e) => {
    const lang_code = e.target.value;
    global.language = lang_code;
    setSession("language", lang_code);
    setLng(lang_code);
    window.location.reload();
  };
  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classnames(
            classes.headerMenuButtonSandwich,
            classes.headerMenuButtonCollapse,
          )}
        >
          {layoutState.isSidebarOpened ? (
            <ArrowBackIcon
              classes={{
                root: classnames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classnames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          )}
        </IconButton>
        <Avatar alt="MM" src={logo} sx={{ width: 110, height: 150 }} />
        <Typography variant="h6" weight="medium" className={classes.logotype}>
          Delivery Management System
        </Typography>

        <div className={classes.grow} />
        <div style={{ padding: 5 }} >
          <select defaultValue={lng} style={{ padding: 3 }} onChange={onChangeLang} >
            {LANGUAGES.map(({ code, label }) => (
              <option key={code} value={code}>
                {label}
              </option>
            ))}
          </select>
        </div>
        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={e => setProfileMenu(e.currentTarget)}
        >
          <AccountIcon classes={{ root: classes.headerIcon }} />
        </IconButton>

        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography variant="h4" weight="medium">
              {fullName}
            </Typography>
          </div>
          <div className={classes.profileMenuUser}>
            <Typography
              className={classes.profileMenuLink}
              color="primary"
              onClick={() => signOut(props)}
            >
              Sign Out
            </Typography>
          </div>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
export default (Header);
