

export function getVechilesReducer(state = {}, action) {    
    switch (action.type) {
        case 'VECHILES_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                Vechiles: action.data
            }
        }
        case 'VECHILES_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success:false,
                Vechiles: action.data
            }
        }
        case 'FETCHING_VECHILES': {
            return {
                ...state,
                Success: false,
                Failure: false,
                Vechiles: action.data
            }
        }
        default: {
            return state;
        }
    }
}
export function getVechileSizeReducer(state = {}, action) {    
    switch (action.type) {
        case 'VECHILE_SIZE_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                VechileSize: action.data
            }
        }
        case 'VECHILE_SIZE_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success:false,
                VechileSize: action.data
            }
        }
        case 'FETCHING_VECHILE_SIZE': {
            return {
                ...state,
                Success: false,
                Failure: false,
                VechileSize: action.data
            }
        }
        default: {
            return state;
        }
    }
}
export function getVechileTypesReducer(state = {}, action) {    
    switch (action.type) {
        case 'VECHILE_TYPES_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                VechileTypes: action.data
            }
        }
        case 'VECHILE_TYPES_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success:false,
                VechileTypes: action.data
            }
        }
        case 'FETCHING_VECHILE_TYPES': {
            return {
                ...state,
                Success: false,
                Failure: false,
                VechileTypes: action.data
            }
        }
        default: {
            return state;
        }
    }
}
export function AddVechileReducer(state = {}, action) {
    
    switch (action.type) {
       
        case 'VECHILE_ADD_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                AddingResponse: action.data
            }
        }
        case 'VECHILE_ADD_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success:false,
                AddingResponse: action.data,
            }
        }
        default: {
            return {
                ...state,
                Failure: false,
                Success:false,
                AddingResponse: null,
            }
        }
    }
}
export function UpdateVechileReducer(state = {}, action) {
    switch (action.type) {
        case 'VECHILE_UPDATE_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                UpdateResponse: action.data
            }
        }
        case 'VECHILE_UPDATE_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success: false,
                UpdateResponse: action.data,
            }
        }
        default: {
            return {
                ...state,
                Failure: false,
                Success: false,
                UpdateResponse: null,
            };
        }
    }
}
export function DeleteVechileReducer(state = {}, action) {
    
    switch (action.type) {
       
        case 'VECHILE_DELETE_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                DeleteResponse: action.data
            }
        }
        case 'VECHILE_DELETE_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success: false,
                DeleteResponse: action.data,
            }
        }
        default: {
            return {
                ...state,
                Failure: false,
                Success: false,
                DeleteResponse: null,
            }
        }
    }
}
