export function getAdhocReducer(state = {}, action) {    
    
    switch (action.type) {
        case 'ADHOC_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                AdhocList: action.data
            }
        }
        case 'ADHOC_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success:false,
                AdhocList: action.data
            }
        }
        case 'FETCHING_ADHOC': {
            return {
                ...state,
                Success: false,
                Failure: false,
                AdhocList: action.data
            }
        }
        default: {
            return state;
        }
    }
}
export function AddAdhocReducer(state = {}, action) {
    switch (action.type) {
        case 'ADHOC_ADD_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                AddingResponse: action.data
            }
        }
        case 'ADHOC_ADD_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success:false,
                AddingResponse: action.data,
            }
        }
        default: {
            return {
                ...state,
                Failure: false,
                Success:false,
                AddingResponse: null,
            }
        }
    }
}
export function UpdateAdhocReducer(state = {}, action) {
    switch (action.type) {
        case 'ADHOC_UPDATE_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                UpdateResponse: action.data
            }
        }
        case 'ADHOC_UPDATE_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success: false,
                UpdateResponse: action.data,
            }
        }
        default: {
            return {
                ...state,
                Failure: false,
                Success: false,
                UpdateResponse: null,
            };
        }
    }
}
export function DeleteAdhocReducer(state = {}, action) {
    
    switch (action.type) {
       
        case 'ADHOC_DELETE_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                DeleteResponse: action.data
            }
        }
        case 'ADHOC_DELETE_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success: false,
                DeleteResponse: action.data,
            }
        }
        default: {
            return {
                ...state,
                Failure: false,
                Success: false,
                DeleteResponse: null,
            }
        }
    }
}

export function getOrderReducer(state = {}, action) {       
    switch (action.type) {
        case 'ORDER_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                OrdersList: action.data
            }
        }
        case 'ORDER_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success:false,
                OrdersList: action.data
            }
        }
        case 'FETCHING_ORDER': {
            return {
                ...state,
                Success: false,
                Failure: false,
                OrdersList: action.data
            }
        }
        default: {
            return state;
        }
    }
}
export function getTeamOrderReducer(state = {}, action) {  
    switch (action.type) {
        case 'ORDER_TEAM_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                TeamOrdersList: action.data
            }
        }
        case 'ORDER_TEAM_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success:false,
                TeamOrdersList: action.data
            }
        }
        case 'FETCHING_TEAM_ORDER': {
            return {
                ...state,
                Success: false,
                Failure: false,
                TeamOrdersList: action.data
            }
        }
        default: {
            return {
                ...state,
                Failure: false,
                Success: false,
                TeamOrdersList: null,
            }
        }
    }
}
export function AdhocScheduleDeliveryReducer(state = {}, action) {
    
    switch (action.type) {
       
        case 'ADHOC_SCHEDULE_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                ScheduleResponse: action.data
            }
        }
        case 'ADHOC_SCHEDULE_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success: false,
                ScheduleResponse: action.data,
            }
        }
        default: {
            return {
                ...state,
                Failure: false,
                Success: false,
                ScheduleResponse: null,
            }
        }
    }
}

export function getSellerReducer(state = {}, action) {       
    switch (action.type) {
        case 'SELLER_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                SellerList: action.data
            }
        }
        case 'SELLER_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success:false,
                SellerList: action.data
            }
        }
        case 'FETCHING_SELLER': {
            return {
                ...state,
                Success: false,
                Failure: false,
                SellerList: action.data
            }
        }
        default: {
            return state;
        }
    }
}

export function SyncOrderReducer(state = {}, action) {
    
    switch (action.type) {
       
        case 'SYNC_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                SyncResponse: action.data
            }
        }
        case 'SYNC_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success: false,
                SyncResponse: action.data,
            }
        }
        default: {
            return {
                ...state,
                Failure: false,
                Success: false,
                SyncResponse: null,
            }
        }
    }
}
export function UpdateSchedulesTeamReducer(state = {}, action) {
    switch (action.type) {
        case 'UPDATE_ORDER_TEAM_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                UpdateOrdTeam: action.data
            }
        }
        case 'UPDATE_ORDER_TEAM_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success: false,
                UpdateOrdTeam: action.data,
            }
        }
        default: {
            return {
                ...state,
                Failure: false,
                Success: false,
                UpdateOrdTeam: null,
            };
        }
    }
}
export function UpdateOrderStatusReducer(state = {}, action) {
    switch (action.type) {
        case 'STATUS_UPDATE_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                UpdateResponse: action.data
            }
        }
        case 'STATUS_UPDATE_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success: false,
                UpdateResponse: action.data,
            }
        }
        default: {
            return {
                ...state,
                Failure: false,
                Success: false,
                UpdateResponse: null,
            };
        }
    }
}
export function RestoreDeliveryReducer(state = {}, action) {
    
    switch (action.type) {
       
        case 'RESTORE_DELIVERY_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                DeleteResponse: action.data
            }
        }
        case 'RESTORE_DELIVERY_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success: false,
                DeleteResponse: action.data,
            }
        }
        default: {
            return {
                ...state,
                Failure: false,
                Success: false,
                DeleteResponse: null,
            }
        }
    }
}

export function getPTripReportReducer(state = {}, action) {    
    
    switch (action.type) {
        case 'PTRIP_REPORT_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                PtripList: action.data
            }
        }
        case 'PTRIP_REPORT_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success:false,
                PtripList: action.data
            }
        }
        case 'FETCHING_PTRIP_REPORT': {
            return {
                ...state,
                Success: false,
                Failure: false,
                PtripList: action.data
            }
        }
        default: {
            return state;
        }
    }
}