import {axios , useSelector} from "./../utilities/PackagesList";
import BaseDetails from './../utilities/BaseDetails.json';
let loginURL = `${BaseDetails.url}:5853/`;//"http://128.199.162.182:5953/";
let orderURL = `${BaseDetails.url}:5852/`;//"http://128.199.162.182:5952/";
let serviceURL = `${BaseDetails.url}:5854/`;//"http://128.199.162.182:5954/";
let notificationURL = `${BaseDetails.url}:5855/`;//"http://128.199.162.182:5955/";
let schedulerURL = `${BaseDetails.url}:5856/`;//"http://128.199.162.182:5956/";
let sellnchill = 'https://sellnchill.com/M3/';
function getBaseUrl(url,type) {
  if (url === "login") {
    axios.defaults.baseURL = loginURL;
    return url;
  }else if(type === "service"){
    axios.defaults.baseURL = serviceURL;
    return url;
  }else if(type === "sms"){
    axios.defaults.baseURL = notificationURL;
    return url;
  }else if(type === "sellnchill"){
    axios.defaults.baseURL = sellnchill;
    return url;
  }else if(type === "b2b"){
    axios.defaults.baseURL = sellnchill+'B2B/';
    return url;
  }else if(type === "scheduler"){
    axios.defaults.baseURL = schedulerURL;
    return url;
  }else{
    axios.defaults.baseURL = orderURL;
    return url;
  }
}
export function returnApiCallPromise(method, url, object = null, config = null, type = null) {
  
  axios.defaults.headers['Content-Type'] = 'application/json';
  axios.defaults.headers['Cache-Control'] = 'no-cache';
  axios.defaults.headers['If-Modified-Since'] = 0;
  axios.defaults.headers['company_id'] = global.CompanyID;
  //axios.defaults.headers['Access-Control-Allow-Origin'] = '*';
  //axios.defaults.baseURL = 'http://128.199.162.182:5952/';
  switch (method) {
    case 'GET':
      return axios.get(getBaseUrl(url,type));
    case 'POST':
      return axios.post(getBaseUrl(url,type), object, config);
    case 'PUT':
      return axios.put(getBaseUrl(url,type), object, config);
    case 'DELETE':
      return axios.delete(getBaseUrl(url,type), object, config);
    case 'FORMPOST': 
      const fpconfigs = {
          headers: {
            'Content-Type': 'multipart/form-data',
            //'Content-Length':JSON.stringify(object).length,
            'content-type': 'multipart/form-data'
        }
      }
      return axios.post(getBaseUrl(url,type), object, fpconfigs);
    case 'FORM': 
      const fconfigs = {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Content-Length':JSON.stringify(object).length,
            'content-type': 'multipart/form-data'
        }
      }
      return axios.put(getBaseUrl(url,type), object, fconfigs);
  }
}

export function returnSellnchillApiPromise(method, url, object = null, config = null, type = null, token = false) {
  delete axios.defaults.headers["company_id"];
  axios.defaults.headers['Content-Type'] = 'application/json';
  axios.defaults.headers['Cache-Control'] = 'no-cache';
  axios.defaults.headers['If-Modified-Since'] = 0;
  if(global.SToken){
    config = {
        headers: { Authorization: `Bearer ${global.SToken}` }
    };
  }else if(token){
    delete axios.defaults.headers["If-Modified-Since"];
    delete axios.defaults.headers["Content-Type"];
    delete axios.defaults.headers["Cache-Control"];
  }
  
  switch (method) {
    case 'GET':
      return axios.get(getBaseUrl(url,type));
    case 'POST':
      return axios.post(getBaseUrl(url,type), object, config);
    case 'PUT':
      return axios.put(getBaseUrl(url,type), object, config);
    case 'DELETE':
      return axios.delete(getBaseUrl(url,type), object, config);
  }
}
export function returnAllApiCallPromise(methodArr, urlArr, arrObject = [], config = null, type = null) {
  delete axios.defaults.headers["company_id"];
  axios.defaults.headers['Content-Type'] = 'application/json';
  axios.defaults.headers['Cache-Control'] = 'no-cache';
  axios.defaults.headers['If-Modified-Since'] = 0;
  config = {
      headers: { Authorization: `Bearer ${global.SToken}` }
  };
  let allPromise = [];
  for(let i=0; i<arrObject.length; i++){
    let object = arrObject.length ? arrObject[i] : null;
    allPromise.push(axios.post(getBaseUrl(urlArr[i],type), object, config))
  }
  return axios.all(allPromise)
}