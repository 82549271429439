import React, { useState, useEffect, Component } from "react";
import {
  Grid, TextField, Button, Switch,
  FormControlLabel, IconButton, DeleteIcon,
  EditIcon, MUIDataTable, Autocomplete, connect,
  _, swal, toast, Typography, Checkbox, Tab,
  TabPanel, AppBar, TabContext, TabList, withStyles
} from "./../../utilities/PackagesList";
import {
  PageTitle, CustomToolbar, AlertDialog,
  CustomModel, InputField, Dropdown,
  Buttons, LoadingOverlay
} from "./../../utilities/ComponentsList";
import { isEmptyObj, dateTimeFormating, todayDate, dateTimetoDate, dateTimetoTime } from "./../../utilities/AppFunctions";
import { getAdhoc, DeleteAdhoc } from './../../api/Adhoc';
import { refreshReduxStates } from './../../api/Refresh';
import { LoaderStatus } from './../../api/Loading';
import { DeliveryList, MockDatas } from "../../utilities/MockData";
import styles from "./../../utilities/StylesClass";
import IMLocalize from "utilities/IMLocalize";
let getAdhocState = false;
class AdhocSchedule extends Component {

  constructor(props) {
    super(props);
    this.state = {
      DialogState: false,
      DeleteID: null,
      EditID: null,
      SelectedIndex: null,
      ModelType: null,
      ModelState: false,
      EditModelState: false,
      AdhocLists: [],
      ReadyList: [],
      ProgressList: [],
      UnDeliverList: [],
      CompletedList: [],
      UnFilteredList: [],
      FilteredList: [],
      DateSelected: "",
      PostalCode: "",
      isLoading: true,
      getCalls: [1],
      postCalls: [0, 0, 0],
      TabSelected: "1"
    }
    props.getAdhoc();
  }

  UNSAFE_componentWillReceiveProps = (props) => {
    if (this.state.getCalls[0]) {
      let succed = this.state.getCalls;
      succed[0] = 1;
      let loaderStatus = getAdhocState ? false : succed.includes(0);

      if (props.AdhocListSuccess) {
        let AdhocDeliveries = _.filter(props.AdhocList, { orderType: "adHocDelivery"});
        let readyItems = _.filter(AdhocDeliveries, function(item) {
          return  todayDate() == dateTimetoDate(item.createdAt)
                  && item.deliveryStatus == "Ready To Deliver";
        });
        getAdhocState = false;
        this.setState({
          AdhocLists: AdhocDeliveries,
          UnFilteredList: AdhocDeliveries,
          FilteredList: readyItems,
          ReadyList: readyItems,
          ProgressList: [],
          UnDeliverList: [],
          CompletedList: [],
          isLoading: loaderStatus,
          getCalls: [0]
        })
      } else if (props.AdhocListFailure) {
        getAdhocState = false;
        this.setState({
          AdhocLists: [],
          ReadyList: [],
          ProgressList: [],
          UnDeliverList: [],
          CompletedList: [],
          getCalls: [0],
          isLoading: loaderStatus
        })
      }
    }
  }
  handleChange = (event, newValue) => {

    let ItemsSelected = [];
    if (newValue == "1") {
      ItemsSelected = _.filter(this.state.UnFilteredList, { deliveryStatus: "Ready To Deliver", createdAt: todayDate() });
    } else if (newValue == "2") {
      ItemsSelected = _.filter(this.state.UnFilteredList, { deliveryStatus: "In Progress", createdAt: todayDate() });
    } else if (newValue == "3") {
      ItemsSelected = _.filter(this.state.UnFilteredList, { deliveryStatus: "Undelivered", createdAt: todayDate() });
    } else if (newValue == "4") {
      ItemsSelected = _.filter(this.state.UnFilteredList, { deliveryStatus: "Completed", createdAt: todayDate() });
    }
    this.setState({
      FilteredList: ItemsSelected,
      TabSelected: newValue,
      DateSelected: "",
      PostalCode: "",
    })

  }
  getStatus=(tabVal)=>{
    if (tabVal == "1") {
      return "Ready To Deliver";
    } else if (tabVal == "2") {
      return "In Progress";
    } else if (tabVal == "3") {
      return "Undelivered";
    } else if (tabVal == "4") {
      return "Completed";
    }
  }
  searchClick = () => {
    //console.log(JSON.stringify(this.state.UnFilteredList))
    if(!this.state.DateSelected && !this.state.PostalCode){
      return;
    }
    let datePicked = this.state.DateSelected ? this.state.DateSelected : todayDate();
    let Status = this.getStatus(this.state.TabSelected);
    let postalSelected = this.state.PostalCode;
    let ItemsSelected = _.filter(this.state.UnFilteredList, function(item) {
      return  datePicked == dateTimetoDate(item.createdAt)
              && item.deliveryStatus == Status
              && (postalSelected ? (postalSelected == item.postalCode): true);
    });
    
     this.setState({
      FilteredList : ItemsSelected
    }) 

  }
  clearSearchClick = () => {
    let LIsts = _.filter(this.state.UnFilteredList, { deliveryStatus: this.getStatus(this.state.TabSelected), createdAt: todayDate() });
    this.setState({
      FilteredList : LIsts,
      DateSelected: "",
      PostalCode: "",
    })

  }
  render() {
    const columns = [
      {
        name: "orderNumber",
        label: "Order Number",
        options: {
          filter: false,
          sort: true,
        }
      },
      {
        name: "customerInvoiceNumber",
        label: "Custom Invoice",
        options: {
          filter: false,
          sort: true,
        }
      },
      /* {
        name: "customerName",
        label: "Customer Name",
        options: {
          filter: false,
          sort: true,
        }
      },
      {
        name: "contactNumber",
        label: "Contact No",
        options: {
          filter: true,
          sort: true,
        }
      }, 
      {
        name: "address",
        label: "Address",
        options: {
          filter: false,
          sort: false,
        }
      },*/
      {
        name: "postalCode",
        label: "Postal Code",
        options: {
          filter: true,
          sort: true,
        }
      },
      {
        name: "createdAt",
        label: "Deliver Date",
        options: {
          filter: true,
          sort: true,
          //filterType:"multiselect",
          filterOptions: {
            renderValue: v => v ? dateTimeFormating(v) : ''
          },
          customFilterListOptions: {
            render: v => {
              return v ? dateTimeFormating(v) : '';
            }
          },
          //filterType: 'custom',
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Grid item md={12}>
                <Typography>{dateTimetoDate(value)}</Typography>
                <Typography>{dateTimetoTime(value)}</Typography>
              </Grid>
            )
          }
        }
      },
      {
        name: "items",
        label: "Products",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            let checkString = value.includes(",");
            let items = checkString ? value.split(",") : [value];
            return (
              <Grid item md={12}>
                {items.map((itemVal, index) => (
                  <Typography>{itemVal}</Typography>
                ))}

              </Grid>
            )
          }
        }
      },
      {
        name: "teamName",
        label: "Team Name",
        options: {
          filter: true,
          sort: true,
        }
      }
    ];
    const { classes } = this.props;
    return (
      <>
        {/* <PageTitle title="" /> */}
        <Grid container spacing={4}>
          <Grid item md={12}>
            <TabContext value={this.state.TabSelected}>
              <AppBar position="static" color="default">
                <TabList onChange={(event, newValue) => this.handleChange(event, newValue)} aria-label="simple tabs example">
                  <Tab label="Ready to Deliver" value="1" />
                  <Tab label="In Progress" value="2" />
                  <Tab label="UnDelivered" value="3" />
                  <Tab label="Completed" value="4" />
                </TabList>
              </AppBar>
              <Grid container spacing={4} md={12} direction={"row"} style={{ marginTop: 30 }}>
                <Grid item md={2.5} >
                  <InputField
                    label={"Delivery Date"}
                    type={"date"}
                    required={true}
                    value={this.state.DateSelected}
                    changeText={(data)=>{this.setState({DateSelected :data ? data.target.value : null})}}
                    // error={Createerrors.Date}
                    errorMsg={"Please enter date."}
                  />
                </Grid>
                <Grid item md={2.5} >
                  <InputField
                    label={"Postal Code"}
                    type={"number"}
                    required={true}
                    value={this.state.PostalCode}
                    changeText={(data)=>this.setState({PostalCode :data ? data.target.value : null})}
                   // error={Createerrors.Date}
                    errorMsg={"Please enter postal code."}
                  />
                </Grid>
                <Grid item md={1} >
                  <Button
                    onClick={() => this.clearSearchClick()}
                    className={classes.buttonCancel}
                    variant="contained" color="primary">
                    Clear
                  </Button>
                </Grid>
                <Grid item md={1} >
                  <Button
                    onClick={() => this.searchClick()}
                    className={classes.buttonSuccess}
                    variant="contained" color="primary">
                    Search
                  </Button>
                </Grid>
              </Grid>
              <Grid item spacing={4} md={12} style={{ marginTop: 30 }}>
                <MUIDataTable
                  //title="Delivery List"
                  data={this.state.FilteredList}
                  columns={columns}
                  options={{
                    textLabels: {
                      body: {
                        noMatch: IMLocalize().nomatchrecords
                      },
                      pagination: {
                        rowsPerPage: IMLocalize().rowsperpage
                      }
                    },
                    sort: true,
                    selectableRows: false,
                    selectableRowsOnClick: false,
                    print: false,
                    download: false,
                    viewColumns: false,
                    responsive:true,
                    filter: false
                  }}
                />
              </Grid>
            </TabContext>

          </Grid>
        </Grid>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    AdhocListSuccess: state.getAdhocReducer.Success,
    AdhocListFailure: state.getAdhocReducer.Failure,
    AdhocList: state.getAdhocReducer.AdhocList,

    UserDetails: state.LoginReducer.UserDetails,
    LoaderState: state.LoaderReducer.State
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAdhoc: () => dispatch(getAdhoc()),
    refreshReduxStates: () => dispatch(refreshReduxStates()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AdhocSchedule));
