import React, { useState, useEffect, Component } from 'react';
import {
    Grid, Dialog, GridList, DialogContent,
    GridListTile, DialogTitle, makeStyles,
    Typography, Divider, IconButton, DeleteIcon,
    EditIcon, _, swal, toast, useSelector, useDispatch,
    Card ,CardMedia, CardContent, CardActions, Box
} from '../../utilities/PackagesList';
import {
    PageTitle, CustomToolbar, AlertDialog,
    CustomModel, InputField, Dropdown,
    Buttons, LoadingOverlay
} from "../../utilities/ComponentsList";
import { isEmptyObj, DMY, AMPM, todayDate } from "../../utilities/AppFunctions";
import { getTeamOrder, AdhocScheduleDelivery } from '../../api/Adhoc';
import { refreshReduxStates } from './../../api/Refresh';
const useStyles = makeStyles(theme => ({
    dialogTitle: {

    },
    dialogWindow:{
        width:'80%'
    },
    listStyle:{
        border:"1px solid #eee",
        padding:10,
        height:"250px",
        width:"100%"
    },
    cardStyle: {
        height:90,
        padding:10,
        margin:10,
        boxShadow: "none",
        borderRadius: 10,
        backgroundColor:'#000'

    },
}))
const AssignTeam = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [Addvalues, setAddValues] = React.useState({
        Team: '',
        Date: '',
        Time: '',
        PTrip: 0,
        TeamSelected: ''
    });
    useEffect(() => {
        dispatch(refreshReduxStates());
    }, [])
    const createState = useSelector((state) => state.AdhocScheduleDeliveryReducer);
    const TeamOrderState = useSelector((state) => state.getTeamOrderReducer);
    let TeamOrders = TeamOrderState.TeamOrdersList ? TeamOrderState.TeamOrdersList : [];
    
    const [Createerrors, setCreateErrors] = useState({});
    let ParentState = props.parentState;
    let TeamListData = ParentState.TeamsLists;
   
    if(Addvalues.Date){
        TeamOrders = _.filter(TeamOrders, { 'deliveryDate': Addvalues.Date });
    }else{
        TeamOrders = _.filter(TeamOrders, { 'deliveryDate': todayDate() });
    }  
    
    TeamOrders = _.orderBy(TeamOrders, ['deliveryTime'], ['asc']);
    let selectedOrd = ParentState.SelectedOrders;
    
    TeamOrders = TeamOrders.filter(function (value, index) {
        let orderExist = selectedOrd.includes(value.id);
        return !orderExist;
      });
    let totalTeamOrders = TeamOrders.length;
    if (createState.Success && !isEmptyObj(createState.ScheduleResponse)) {
        toast.success('Scheduled Successfully!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
        });
        let ptripVal = Addvalues.PTrip ? Addvalues.PTrip : 0;
        let addedObject = {
            teamId : Addvalues.TeamSelected,
            teamName: Addvalues.Team.teamName,
            date: Addvalues.Date,
            time:Addvalues.Time,
            pTrip:parseFloat(ptripVal).toFixed(2)
        }
        props.parentClass.AssignedTeam(addedObject)
    } else if (createState.Failure && !isEmptyObj(createState.ScheduleResponse)) {
        toast.error(createState.ScheduleResponse.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
        });
    }
    const handleChange = (data,name) => {
        let targetValue = data.target.value;
        setAddValues({ ...Addvalues, [name]: targetValue });
        setCreateErrors({ ...Createerrors, [name]: targetValue ? false : true })
    };

    const submitForm = () => {
        let srrorObj = {};
        let errorStatus = false;
        Object.keys(Addvalues).map(function (k) {
            if (k === 'PTrip') {
                srrorObj[k] = false;
                errorStatus = errorStatus ? true : false;
            } else {
                srrorObj[k] = Addvalues[k] ? false : true;
                errorStatus = errorStatus ? true : !Addvalues[k];
            }
        })
        setCreateErrors(srrorObj);
        if (!errorStatus) {
            let ptripVal = Addvalues.PTrip ? Addvalues.PTrip : 0;
            let addObject = {
                teamId : Addvalues.TeamSelected,
                orderIds: ParentState.SelectedOrders,
                date: Addvalues.Date,
                time:Addvalues.Time,
                pTrip:parseFloat(ptripVal).toFixed(2)
            }
            dispatch(AdhocScheduleDelivery(addObject));
        }
    };
    
    return (
        <Dialog
            open={props.Status}
            //onClose={()=>this.closeDialog()}
            fullWidth={true}
            fullScreen={false}
            maxWidth="md"
          //  className={classes.dialogWindow}
            disableBackdropClick={false}
            disableEscapeKeyDown={false}
        >
            <DialogTitle className={classes.dialogTitle} id="dialog-title">{props.Title}</DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={4}>
                    <Grid item md={12}>
                        <Grid
                            container
                            spacing={1}
                            direction="row"
                        >
                            <Grid
                                container
                                item
                                md={3}
                                spacing={1}
                            >
                                <Grid item md={12} >
                                    <Dropdown
                                        label={"Select Team"}
                                        data={TeamListData}
                                        getOptionLabel={option => (option.teamName ? option.teamName : "")}
                                        getOptionSelected={(option, value) => option.id === value.id}
                                        value={Addvalues.Team}
                                        ItemChange={(event, newValue) => {
                                            //TeamOrders = [];
                                            setAddValues({
                                                ...Addvalues,
                                                TeamSelected: newValue ? newValue.id : null,
                                                Team: newValue ? newValue : null
                                            });
                                            setCreateErrors({
                                                ...Createerrors,
                                                TeamSelected: newValue ? false : true,
                                                Team: newValue ? false : true
                                            })
                                            if(newValue){
                                                dispatch(getTeamOrder(newValue.id));
                                            }else{
                                                dispatch(refreshReduxStates());
                                            }
                                            
                                        }}
                                        error={Createerrors.TeamSelected}
                                        initialName={"Team"}
                                        errorMsg={"Please choose team."}
                                    />
                                </Grid>
                                <Grid item md={12}>
                                    <InputField
                                        label={"Select Date"}
                                        type={"date"}
                                        required={true}
                                        value={Addvalues.Date}
                                        changeText={(data)=>handleChange(data ,'Date')}
                                        error={Createerrors.Date}
                                        initialName={"Date"}
                                        errorMsg={"Please enter date."}
                                    />
                                </Grid>
                                <Grid item md={12}>
                                    <InputField
                                        label={"Select Time"}
                                        type={"time"}
                                        required={true}
                                        value={Addvalues.Time}
                                        changeText={(data)=>handleChange(data ,'Time')}
                                        error={Createerrors.Time}
                                        initialName={"Time"}
                                        errorMsg={"Please enter time."}
                                    />
                                </Grid>
                                {/* <Grid item md={12}>
                                    <InputField
                                        label={"P-Trip"}
                                        type={"text"}
                                        required={true}
                                        value={Addvalues.PTrip}
                                        changeText={(data)=>handleChange(data ,'PTrip')}
                                        error={Createerrors.PTrip}
                                        initialName={"P-Trip"}
                                        errorMsg={"Please enter p-trip."}
                                    />
                                </Grid> */}
                            </Grid>
                            <Grid
                                item
                                md={9}
                                spacing={1}
                            >
                                <GridList padding={20}  cols={3}  
                                style={{height:"250px",
                                alignContent:'flex-start',
                                justifyContent:'flex-start',
                                marginLeft:20
                                }}>
                               
                                    {TeamOrders.map((items) => (
                                        <GridListTile  
                                            style={{ 
                                            height:70,
                                            width:200,
                                            padding:10,
                                            margin:5,
                                            boxShadow: "1px 1px 5px 1px #9E9E9E",
                                            borderRadius: 10,
                                        }} key={items.ID} >
                                            <Typography style={{ fontWeight: "bold" }} >
                                               Invoice: {items.customerInvoiceNumber}
                                            </Typography>
                                            <Typography >
                                               Postal Code: {items.postalCode}
                                            </Typography>
                                            <Typography >
                                                {DMY(items.deliveryDate)} {AMPM(items.deliveryTime)}
                                            </Typography>
                                            {/* <Divider /> */}
                                        </GridListTile>

                                    ))}
                                    {TeamOrders.length<=0 ? 
                                        <GridListTile  style={{ height: 'auto',paddingBottom:"10px"}} rows={1}>
                                        <Typography  >
                                           No orders assigned to the selected team
                                        </Typography>
                                    </GridListTile>
                                        : null}
                                </GridList>
                               {/*  <GridList container direction="row"
                                    justify="center"
                                    alignItems="center" 
                                    className={{maxHeight:50}}
                                >
                                    {TeamOrders.map((items) => (
                                        <Grid item md={4} className={classes.cardStyle}>
                                            <Typography style={{ fontWeight: "bold" }} >
                                               Cust. Invoice: {items.customerInvoiceNumber}
                                            </Typography>
                                            <Typography >
                                               Postal Code: {items.postalCode}
                                            </Typography>
                                            <Typography >
                                                {DMY(items.deliveryDate)} {AMPM(items.deliveryTime)}
                                            </Typography>
                                           
                                        </Grid>
                                    ))}
                                </GridList> */}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={12} style={{ display: "flex", justifyContent: "flex-end" }} >
                        <Typography  >
                            Total Delivery : {totalTeamOrders}
                        </Typography>
                    </Grid>
                    <Buttons
                        close={() => props.parentClass.CloseAssign()}
                        closeText={"Cancel"}
                        save={()=>submitForm()}
                        saveText={"Submit"}
                    />
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default AssignTeam;