import React, { useState, useEffect, Component } from "react";
import {
  Grid, TextField, Button, Switch,
  FormControlLabel, IconButton, DeleteIcon,
  EditIcon, MUIDataTable, Autocomplete, connect,
  _, swal, toast, Typography, Checkbox, Tab,
  TabPanel, AppBar, TabContext, TabList, withStyles,
  CardMedia, SRLWrapper, SimpleReactLightbox,
  Table, TableBody, TableCell, TableHead,
  TableContainer, TableRow, Paper,
  Tooltip
} from "../../utilities/PackagesList";
import {
  PageTitle, CustomToolbar, AlertDialog,
  CustomModel, InputField, Dropdown,
  Buttons, LoadingOverlay, ButtonIcon
} from "../../utilities/ComponentsList";
import {
  isEmptyObj, dateTimeFormating, todayDate,
  LastDayMonth, DateMinusYMD, AMPM,
  convertDateDMY, nullToStr, CDatePlusYMD
} from "../../utilities/AppFunctions";
import { getAdhoc, getSeller, getOrderByDelivery, getCustomers } from '../../api/Adhoc';
import { getTeams } from '../../api/Team';
import { refreshReduxStates } from '../../api/Refresh';
import styles from "../../utilities/StylesClass";
import BaseDetails from '../../utilities/BaseDetails.json';
import ReactToPrint from 'react-to-print';
import IMLocalize from "utilities/IMLocalize";

class ProductReport extends Component {

  constructor(props) {
    super(props);
    this.state = {
      MarketPlaceID: "",
      MarketPlaceObj: "",
      SellersLists: [],
      OrderLists: [],
      DelStartDate: todayDate(),
      DelEndDate: CDatePlusYMD(todayDate(), 5),
      TeamSelectedID: "",
      TeamSelected: "",
      isLoading: true,
      getCalls: [1, 1],
      TeamsLists: [],
      retailerList: [],
      customerID: 0,
      customerSelected : null

    }
    props.getTeams(2);
    props.getSeller();
    this.getOrderItems();
    this.getReatilers();
  }

  UNSAFE_componentWillReceiveProps = (props) => {

    if (this.state.getCalls[0]) {
      if (props.TeamListSuccess) {
        let succed = this.state.getCalls;
        succed[0] = 0;
        let loaderStatus = succed.includes(1);
        let newTeam = {
          "id": 0,
          "teamName": "All",
          "vehicleNumber": "",
          "vehicelId": "",
          "assignedStaff": [],
          "teamTypeId": 0,
          "teamTypeName": "all"
        };
        let Teams = props.TeamLists;
        Teams.unshift(newTeam);
        this.setState({
          TeamsLists: props.TeamLists,
          isLoading: loaderStatus,
          TeamSelectedID: Teams[0].id,
          TeamSelected: Teams[0],
          getCalls: succed
        })
      } else if (props.TeamListFailure) {
        let succed = this.state.getCalls;
        succed[0] = 0;
        let loaderStatus = succed.includes(1);
        this.setState({
          TeamsLists: [],
          getCalls: succed,
          isLoading: loaderStatus
        })
      }
    }
    if (this.state.getCalls[1]) {
      if (props.SellerListSuccess) {
        let succed = this.state.getCalls;
        succed[1] = 0;
        let loaderStatus = succed.includes(1);
        let newSeller = {
          "id": 0,
          "name": "All"
        };
        let Sellers = props.SellerLists;
        Sellers.unshift(newSeller)
        this.setState({
          SellersLists: Sellers,
          isLoading: loaderStatus,
          MarketPlaceID: Sellers[0].id,
          MarketPlaceObj: Sellers[0],
          getCalls: succed
        })
      } else if (props.SellerListFailure) {
        let succed = this.state.getCalls;
        succed[1] = 0;
        let loaderStatus = succed.includes(1);
        this.setState({
          SellersLists: [],
          getCalls: succed,
          isLoading: loaderStatus
        })
      }
    }
  }
  getReatilers = async () => {
    let filterObj = {
      companyID: global.CompanyID
    }
    const custList = await getCustomers(filterObj);
    this.setState({
      retailerList: custList.data
    })
  }
  getOrderItems = async () => {
    let succed = this.state.getCalls;
    let loaderStatus = succed.includes(1);
    if (!loaderStatus) {
      this.setState({
        OrderLists: [],
        isLoading: true
      })
    }
    let DeliveryStart = this.state.DelStartDate ? this.state.DelStartDate : todayDate();
    let DeliveryEnd = this.state.DelEndDate ? this.state.DelEndDate : CDatePlusYMD(todayDate(), 5);
    let SellerType = this.state.MarketPlaceID ? this.state.MarketPlaceID : "";
    let SellerTypeObj = this.state.MarketPlaceObj;
    let TeamSelectedID = this.state.TeamSelectedID;
    let CustomerID = this.state.customerID;
    let filterObj = {
      StartDate: DeliveryStart,
      EndDate: DeliveryEnd,
      OrderType: "",
      DeliveryStatus: "",
      TeamID: TeamSelectedID,
      CustomerID: CustomerID,
      SellerType: parseInt(SellerType) > 0 ? SellerTypeObj.name.toLowerCase() : ""
    }
    const ordrList = await getOrderByDelivery(filterObj);

    this.setState({
      OrderLists: ordrList.orders,
      isLoading: false
    })

  }
  searchClick = () => {
    if ((!this.state.DelStartDate || !this.state.DelEndDate) && !this.state.MarketPlaceID
      && !this.state.TeamSelectedID && !this.state.customerID) {
      toast.warning('Please choose any one filter option!');
      return;
    }
    this.getOrderItems();
  }

  clearSearchClick = () => {
    this.setState({
      OrderLists: [],
      DelStartDate: todayDate(),//DateMinusYMD(5),
      DelEndDate: CDatePlusYMD(todayDate(), 5),//todayDate(),
      TeamSelectedID: "",
      TeamSelected: "",
      customerID: 0
    });

  }

  renderTable = (renderData) => {
    const { classes } = this.props;
    const { OrderLists } = this.state;
    const groupByTeam = _.groupBy(OrderLists, 'teamName');
    return (
      <TableContainer component={Paper} className={[classes.paper]} ref={el => (this.componentRef = el)}>
        <Table aria-label="product table" stickyHeader>
          <TableHead  >
            <TableRow >
              <TableCell style={{ minWidth: 100, position: "sticky" }}>
                <Typography className={[classes.boldText7]}>{IMLocalize().teamName}</Typography>
              </TableCell>
              <TableCell style={{ minWidth: 150, position: "sticky" }}>
                <Typography className={[classes.boldText7]}>{IMLocalize().customer}</Typography>
              </TableCell>
              <TableCell style={{ minWidth: 150, position: "sticky" }}>
                <Typography className={[classes.boldText7]}>{IMLocalize().deliveryDate}</Typography>
              </TableCell>
              <TableCell style={{ minWidth: 150, position: "sticky" }}>
                <Typography className={[classes.boldText7]}>{IMLocalize().products}</Typography>
              </TableCell>
              <TableCell style={{ minWidth: 150, position: "sticky" }}>
                <Typography className={[classes.boldText7]}>{IMLocalize().quantity}</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/*  {
                  OrderLists[0].map((row, index) => {
                      let searchDate = row.date;
                      return (
                          <TableCell style={{ minWidth: 150 }}>
                              <Typography align="center" className={[classes.boldText7]}>{DDMY(searchDate)}</Typography>
                          </TableCell>
                      )

                  })
              } */}
            {

              Object.entries(groupByTeam).map(([key, value]) => {
                const validateTeam = key && key != "null" ? key : "Not Assigned";
                return (
                  < >


                    {
                      value.map((row, index) => {
                        const rowCnt = value.length;
                        return (
                          <TableRow >
                            {index === 0 &&
                              <TableCell rowSpan={rowCnt} >
                                <Typography className={[classes.boldText7]}>{validateTeam}</Typography>
                              </TableCell>
                            }
                            <TableCell >
                              <TableRow >
                                <Typography  >{row.customerName}</Typography>
                              </TableRow>
                            </TableCell>
                            <TableCell >
                              <TableRow >
                                <Typography  >{row.deliveryDate}</Typography>
                              </TableRow>
                            </TableCell>
                            <TableCell >
                              <TableRow >
                                <Typography  >{row.productName}</Typography>
                              </TableRow>
                            </TableCell>
                            <TableCell >
                              <TableRow >
                                <Typography  >{row.productQty}</Typography>
                              </TableRow>
                            </TableCell>
                          </TableRow>
                        )

                      })
                    }

                  </>
                )
              })
            }
            {/* <TableCell className={classes.stickyItem}>
                          <Typography  className={[classes.boldText7]}>{"row.teamName"}</Typography>
                      </TableCell>
                      <TableCell className={classes.stickyItem}>
                          <Typography  className={[classes.boldText7]}>{"row.teamName"}</Typography>
                      </TableCell>
                      <TableCell className={classes.stickyItem}>
                      <TableRow style={{ height: "100%", width: '100%' }} >
                          <Typography  className={[classes.boldText7]}>Products</Typography>
                      </TableRow>
                      <TableRow style={{ height: "100%", width: '100%' }} >
                          <Typography  className={[classes.boldText7]}>Products</Typography>
                      </TableRow>
                      <TableRow style={{ height: "100%", width: '100%' }} >
                          <Typography  className={[classes.boldText7]}>Products</Typography>
                      </TableRow>
                      </TableCell>
                      <TableCell className={classes.stickyItem}>
                          <Typography align="right" className={[classes.boldText7]}>{"row.teamName"}</Typography>
                      </TableCell> */}

          </TableBody>


        </Table>
      </TableContainer>
    )
  }
  render() {
    const { classes } = this.props;
    const {MarketPlaceObj} = this.state;
    const selectedSeller = MarketPlaceObj?.name?.toLowerCase() || '';
   // alert(selectedSeller)
    return (
      <>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={1} md={12} direction={"row"}>
              <Grid item md={2} >
                <InputField
                  label={IMLocalize().dlvStartDate}
                  type={"date"}
                  required={false}
                  value={this.state.DelStartDate}
                  changeText={(data) => {
                    this.setState({
                      DelStartDate: data ? data.target.value : null,
                      DelEndDate: data ? LastDayMonth(data.target.value) : null
                    })
                  }}
                  initialName={IMLocalize().dlvStartDate}
                  errorMsg={"Please enter start date."}
                />
              </Grid>
              <Grid item md={2} >
                <InputField
                  label={IMLocalize().dlvEndDate}
                  type={"date"}
                  required={false}
                  value={this.state.DelEndDate}
                  changeText={(data) => { this.setState({ DelEndDate: data ? data.target.value : null }) }}
                  // error={Createerrors.Date}
                  initialName={IMLocalize().dlvEndDate}
                  errorMsg={"Please enter end date."}
                />
              </Grid>
              <Grid item md={2}>
                <Dropdown
                  label={IMLocalize().marketPlace}
                  data={this.state.SellersLists}
                  getOptionLabel={option => (option.name ? option.name : "")}
                  getOptionSelected={(option, value) => option.id === value.id}
                  value={this.state.MarketPlaceObj}
                  ItemChange={(event, newValue) => {
                    this.setState({
                      MarketPlaceID: newValue ? newValue.id : null,
                      MarketPlaceObj: newValue ? newValue : null
                    })
                  }}
                  // error={this.state.syncErrors[2]}
                  initialName={IMLocalize().marketPlace}
                  errorMsg={"Please choose marketplace."}
                />
              </Grid>
              {selectedSeller=='b2b' && <Grid item md={2} >
                <Dropdown
                  label={IMLocalize().customer}
                  data={this.state.retailerList}
                  getOptionLabel={option => (option.customer ? option.customer : "")}
                  getOptionSelected={(option, value) => option.b2BCustomerID === value.b2BCustomerID}
                  value={this.state.customerSelected}
                  ItemChange={(event, newValue) => {
                    this.setState({
                      customerID: newValue ? newValue.b2BCustomerID : null,
                      customerSelected: newValue ? newValue : null
                    })
                  }}
                  //error={Createerrors.TeamSelectedID}
                  initialName={IMLocalize().customer}
                  errorMsg={"Please choose customer."}
                />

              </Grid>}
              
              <Grid item md={1} >
                <Button
                  onClick={() => this.clearSearchClick()}
                  className={classes.buttonCancel}
                  variant="contained" color="primary">
                  {IMLocalize().clear}
                </Button>
              </Grid>
              <Grid item md={1} >
                <Button
                  onClick={() => this.searchClick()}
                  className={classes.buttonSuccess}
                  variant="contained" color="primary">
                  {IMLocalize().search}
                </Button>
              </Grid>
            </Grid>
            <Grid container justify="flex-end">
              <ReactToPrint
                trigger={() => {
                  // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                  // to the root node of the returned component as it will be overwritten.
                  return <Button
                    //onClick={() => this.clearSearchClick()}
                    className={classes.buttonCancel}
                    variant="contained" color="primary">
                    {IMLocalize().print}
                  </Button>;
                }}
                content={() => this.componentRef}
              />
            </Grid>
            <Grid item xs={12} style={{ marginTop: 10 }}>
              {this.renderTable()}
            </Grid>

          </Grid>
          <LoadingOverlay Status={this.state.isLoading} />
        </Grid>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    TeamListSuccess: state.getTeamsReducer.Success,
    TeamListFailure: state.getTeamsReducer.Failure,
    TeamLists: state.getTeamsReducer.TeamList,

    SellerListSuccess: state.getSellerReducer.Success,
    SellerListFailure: state.getSellerReducer.Failure,
    SellerLists: state.getSellerReducer.SellerList,

    UserDetails: state.LoginReducer.UserDetails,
    LoaderState: state.LoaderReducer.State
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTeams: (ID) => dispatch(getTeams(ID)),
    getSeller: () => dispatch(getSeller()),
    refreshReduxStates: () => dispatch(refreshReduxStates()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProductReport));
