import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import {
  NavigateNext as NextIcon,
  ArrowBack as ArrowBackIcon,
  Person as EmployeeIcon,
  DirectionsCar as VehicleIcon,
  People as Team,
  LocalShipping as DeliveryIcon,
  Update as UpdateIcon,
  Equalizer as StatusIcon,
  Build as SpanerIcon,
  Assessment as ReportIcon,
  List as ListIcon,
  Settings as SettingsIcon,
  VerticalSplit as SplitIcon
} from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";
import Dot from "./components/Dot";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import IMLocalize from "./../../utilities/IMLocalize";

const structure = [
  {
    id: 0,
    label: IMLocalize().vehicles,//"Vehicles",
    link: "/app/vehicles",
    icon: <VehicleIcon fontSize="small" />,
  },
  {
    id: 1,
    label: IMLocalize().staffs,//"Staffs",
    link: "/app/staffs",
    icon: <EmployeeIcon fontSize="small" />,
  },
  {
    id: 2,
    label:IMLocalize().teams,//"Teams",
    link: "/app/teams",
    icon: <Team fontSize="small" />
  },
  {
    id: 3,
    label: IMLocalize().marketplaceDelivery,//"Marketplace Delivery",
    link: "/app/delivery",
    icon: <UpdateIcon fontSize="small" />
  },
  {
    id: 4,
    label: IMLocalize().adHocDelivery,//"Ad Hoc Delivery",
    link: "/app/adhocdelivery",
    icon: <DeliveryIcon fontSize="small" />
  },
  {
    id: 5,
    label: IMLocalize().deliveryStatus,//"Delivery Status",
    link: "/app/deliverystatus",
    icon: <StatusIcon fontSize="small" />
  },
  {
    id: 6,
    label: IMLocalize().deliveryReport,//"Delivery Report",
    link: "",
    children: [
      {
        id: 1,
        label: IMLocalize().delivery,//"Delivery",
        link: "/app/deliveryreport",
        icon: <NextIcon fontSize="small" />
      },
       {
        id: 2,
        label: IMLocalize().product,//"Product",
        link: "/app/productreport",
        icon: <NextIcon fontSize="small" />
      }
    ],
    icon: <SplitIcon fontSize="small" />
  },
  /* {
    id: 7,
    label: "Create Service",
    link: "/app/createservice",
    icon: <SpanerIcon fontSize="small" />
  },
  {
    id: 8,
    label: "Ad Hoc Sales/Service",
    link: "/app/adhocservice",
    icon: <ListIcon fontSize="small" />
  },
  {
    id: 9,
    label: "Service List",
    link: "/app/service",
    icon: <ListIcon fontSize="small" />
  },
  {
    id: 10,
    label: "Service Status",
    link: "/app/servicestatus",
    icon: <ReportIcon fontSize="small" />
  },
  {
    id: 11,
    label: "Service Report",
    link: "",
    children: [
      {
        id: 1,
        label: "Technician",
        link: "/app/techreport",
        icon: <NextIcon fontSize="small" />
      },{
        id: 2,
        label: "Collection",
        link: "/app/collectionreport",
        icon: <NextIcon fontSize="small" />
      },
      {
        id: 3,
        label: "Job Types",
        link: "/app/jobtypereport",
        icon: <NextIcon fontSize="small" />
      },
      {
        id: 4,
        label: "Balance Payment",
        link: "/app/paymentreport",
        icon: <NextIcon fontSize="small" />
      },
      {
        id: 4,
        label: "Customers",
        link: "/app/lastservicereport",
        icon: <NextIcon fontSize="small" />
      }
    ],
    icon: <SplitIcon fontSize="small" />
  }, */
  /* {
    id: 11,
    label: "Time Slot Report",
    link: "/app/timeslotreport",
    icon: <ReportIcon fontSize="small" />
  }, */
 /*  {
    id: 12,
    label: "Masters",
    link: "",
    children: [
      {
        id: 1,
        label: "Job Types",
        link: "/app/jobtypes",
        icon: <NextIcon fontSize="small" />
      },
      {
        id: 2,
        label: "Payment Types",
        link: "/app/paymenttypes",
        icon: <NextIcon fontSize="small" />
      },
      {
        id: 3,
        label: "Time Slots",
        link: "/app/timeslots",
        icon: <NextIcon fontSize="small" />
      },
      {
        id: 4,
        label: "Leaves",
        link: "/app/leaves",
        icon: <NextIcon fontSize="small" />
      } ,
      {
        id: 5,
        label: "Service Forms",
        link: "/app/forms",
        icon: <NextIcon fontSize="small" />
      } 
    ],
    icon: <SettingsIcon fontSize="small" />
  }, */
];

function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(isSidebarOpened ? classes.drawerMainOpen : classes.drawerMainClose, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {structure.map(link => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ))}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
