import { getSession } from "./AppFunctions";

const IMLocalize = () => {

    const langs = {
        en: {
            vehicles: "Vehicles",
            staffs: "Staffs",
            teams: "Teams",
            marketplaceDelivery: "Marketplace Delivery",
            adHocDelivery: "Ad Hoc Delivery",
            deliveryStatus: "Delivery Status",
            deliveryReport: "Delivery Report",
            delivery: "Delivery",
            product: "Product",
            vehicleList: "Vehicle List",
            vehicletype: "Vehicle Type",
            vehicleName: "Vehicle Name",
            vehicleNumber: "Vehicle Number",
            vehicleSize: "Vehicle Size",
            calcel: "Cancel",
            submit: "Submit",
            actions: "Actions",
            nomatchrecords: "Sorry, no matching records found",
            rowsperpage: "Rows per page:",
            staffList: "Staff List",
            addStaff: "Add Staff",
            staffType: "Staff Type",
            employeeId: "Employee ID",
            firstName: "First Name",
            lastName: "Last Name",
            email: "Email",
            phone: "Phone",
            password: "Password",
            empId: "Emp ID",
            name: "Name",
            status: "Status",
            teamsList: "Teams List",
            teamName: "Team Name",
            teamType: "Team Type",
            staff: "Staff",
            sno: "SNO",
            staffName: "Staff Name",
            startDate: "Start Date",
            endDate: "End Date",
            marketPlace: "Marketplace",
            syncFromb2b: "Sync From B2B",
            filters: "Filters",
            deliveryDate: "Delivery Date",
            contactNumber: "Contact Number",
            customerName: "Customer Name",
            clear: "Clear",
            search: "Search",
            scheduleDelivery: "Schedule Delivery",
            marketPlaceDlvList: "Marketplace Delivery List",
            select: "Select",
            orderNumber: "Order Number",
            customInvoice: "Custom Invoice",
            orderDate: "Order Date",
            address: "Address",
            items: "Items",
            remarks: "Remarks",
            postalCode: "Postal Code",
            adhocDeliveryList: "Ad Hoc Delivery List",
            items2Deliver: "Items to Deliver",
            ready2Deliver:"Ready to Deliver",
            inProgress:"In Progress",
            unDelivered:"UnDelivered",
            completed:"Completed",
            dlvStartDate:"Delivery Start Date",
            dlvEndDate:"Delivery End Date",
            orderStartDate:"Order Start Date",
            orderEndDate:"Order End Date",
            selectTeam:"Select Team",
            customerMobile:"Customer Mobile",
            downloadCSV:"Download CSV",
            print:"Print",
            deliverDate:"Deliver Date",
            products:"Products",
            contactNo:"Contact No",
            completedDate:"Completed Date",
            signature:"Signature",
            images:"Images",
            canceledDate:"Canceled Date",
            pTrip:"P-Trip",
            deliveryTime:"Delivery Time",
            quantity:"Quantity",
            team:"Team",
            dateofDelivery:"Date of Delivery",
            customer:"Customer"
        },
        zh: {
            vehicles: "车辆",
            staffs: "员工",
            teams: "团队",
            marketplaceDelivery: "市场配送",
            adHocDelivery: "临时配送",
            deliveryStatus: "配送状态",
            deliveryReport: "配送报告",
            delivery: "送货",
            product: "产品",
            vehicleList: "车辆清单",
            vehicletype: "车辆类型",
            vehicleName: "车辆名称",
            vehicleNumber: "车辆编号",
            vehicleSize: "车辆尺寸",
            calcel: "取消",
            submit: "提交",
            actions: "操作",
            nomatchrecords: "对不起，未找到匹配记录",
            rowsperpage: "每页行数",
            staffList: "员工清单",
            addStaff: "添加员工",
            staffType: "员工类型",
            employeeId: "员工编号",
            firstName: "名",
            lastName: "姓",
            email: "电子邮件",
            phone: "电话",
            password: "密码",
            empId: "员工编号",
            name: "名字",
            status: "状态",
            teamsList: "团队清单",
            teamName: "团队名称",
            teamType: "团队类型",
            staff: "员工",
            sno: "序号",
            staffName: "员工姓名",
            startDate: "开始日期",
            endDate: "结束日期",
            marketPlace: "市场",
            syncFromb2b: "从 B2B 同步",
            filters: "过滤器",
            deliveryDate: "交货日期",
            contactNumber: "联系电话",
            customerName: "客户名称",
            clear: "清除",
            search: "搜索",
            scheduleDelivery: "安排交货",
            marketPlaceDlvList: "市场配送清单",
            select: "选择",
            orderNumber: "订单编号",
            customInvoice: "定制发票",
            orderDate: "订单日期",
            address: "地址",
            items: "项目",
            remarks: "备注",
            postalCode: "邮政编码",
            adhocDeliveryList: "临时交货清单",
            items2Deliver: "交货项目",
            ready2Deliver:"准备交付",
            inProgress:"进行中",
            unDelivered:"未交付",
            completed:"已完成",
            dlvStartDate:"交付开始日期",
            dlvEndDate:"交付结束日期",
            orderStartDate:"订单开始日期",
            orderEndDate:"订单结束日期",
            selectTeam:"选择团队",
            customerMobile:"客户手机号码",
            downloadCSV:"下载 CSV",
            print:"打印",
            deliverDate:"交付日期",
            products:"产品",
            contactNo:"联系电话",
            completedDate:"完成日期",
            signature:"Signature",
            images:"Images",
            canceledDate:"取消日期",
            pTrip:"P-Trip",
            deliveryTime:"Delivery Time",
            quantity:"数量",
            team:"团队",
            dateofDelivery:"交付日期",
            customer:"客户"
        }
    }
    const lng = getSession("language");
    return langs[lng ? lng : "en"]
};
export default IMLocalize;