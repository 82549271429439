import { returnApiCallPromise } from './ApiContent';
export function getAdhoc(filterObj) {
    let patameter = "schedule-delivery";
    if(filterObj){
        patameter = `schedule-delivery?`+
        (filterObj.StartDate ? `deliveryStartDate=`+filterObj.StartDate : "deliveryStartDate=")+
        (filterObj.EndDate ? `&deliveryEndDate=`+filterObj.EndDate : "")+
        (filterObj.OrderType ? `&orderType=`+filterObj.OrderType : "")+
        (filterObj.DeliveryStatus ? `&deliveryStatusId=`+filterObj.DeliveryStatus : "")+
        (filterObj.OrderStartDate ? `&orderStartDate=`+filterObj.OrderStartDate : "")+
        (filterObj.OrderEndDate ? `&orderEndDate=`+filterObj.OrderEndDate : "")+
        (filterObj.TeamID ? `&teamID=`+filterObj.TeamID : "")+
        (filterObj.sellerType ? `&sellerType=`+filterObj.sellerType : "");
    }
    return (dispatch) => {
        dispatch({ type: 'FETCHING_ADHOC' });
        returnApiCallPromise("GET", patameter)
            .then(response => {            
                dispatch({
                    type: 'ADHOC_SUCCESS',
                    data: response.data.data
                })
            }).catch(error => {
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                } 
                dispatch({
                    type: 'ADHOC_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function UpdateAdhoc(UpdateObject, ID) {
   
    let subURL = "order/"+ID;
   
    return (dispatch) => {
        returnApiCallPromise("PUT", subURL, UpdateObject)
            .then(response => { 
                if(response.data.error){
                    dispatch({
                        type: 'ADHOC_UPDATE_FAILURE',
                        data: response.data.message
                    })
                }else{
                    dispatch({
                        type: 'ADHOC_UPDATE_SUCCESS',
                        data: response.data
                    })
                }  
            }).catch(error => { 
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                } 
                dispatch({
                    type: 'ADHOC_UPDATE_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function DeleteAdhoc(ID) {
   
    let subURL = "order/"+ID;
    return (dispatch) => {
        returnApiCallPromise("DELETE", subURL, null)
            .then(response => {
                if(response.data.error){
                    dispatch({
                        type: 'ADHOC_DELETE_FAILURE',
                        data: response.data.message
                    })
                }else{
                    dispatch({
                        type: 'ADHOC_DELETE_SUCCESS',
                        data: response.data
                    })
                } 
            }).catch(error => { 
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                } 
                dispatch({
                    type: 'ADHOC_DELETE_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function AddAdhoc(AddObject) {
   
    let subURL = "order/";
    return (dispatch) => {
        returnApiCallPromise("POST", subURL, AddObject)
            .then(response => { 
                if(response.data.error){
                    dispatch({
                        type: 'ADHOC_ADD_FAILURE',
                        data: response.data.message
                    })
                }else{
                    dispatch({
                        type: 'ADHOC_ADD_SUCCESS',
                        data: response.data
                    })
                } 
            }).catch(error => { 
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                } 
                dispatch({
                    type: 'ADHOC_ADD_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function getTeamOrder(ID) {
    let patameter = "order-by-team/"+ID;
    return (dispatch) => {
        dispatch({ type: 'FETCHING_TEAM_ORDER' });
        returnApiCallPromise("GET", patameter)
            .then(response => {            
                dispatch({
                    type: 'ORDER_TEAM_SUCCESS',
                    data: response.data.data
                })
            }).catch(error => {
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                } 
                dispatch({
                    type: 'ORDER_TEAM_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function getOrder(filterObj) {
    let patameter = "order";
    if(filterObj){
        patameter = `order?orderStartDate=`+filterObj.StartDate+
        `&orderEndDate=`+filterObj.EndDate+
        (filterObj.DeliveryDate ? `&deliveryDate=`+filterObj.DeliveryDate : "")+
        (filterObj.CustomerName ? `&customerName=`+filterObj.CustomerName : "")+
        (filterObj.OrderType ? `&orderType=`+filterObj.OrderType : "")+
        (filterObj.DeliveryStatus ? `&deliveryStatusId=`+filterObj.DeliveryStatus : "")+
        (filterObj.SellerType ? `&sellerType=`+filterObj.SellerType : "");
    }
    return (dispatch) => {
        dispatch({ type: 'FETCHING_ORDER' });
        returnApiCallPromise("GET", patameter)
            .then(response => {  
                dispatch({
                    type: 'ORDER_SUCCESS',
                    data: response.data.data
                })
            }).catch(error => {
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                } 
                dispatch({
                    type: 'ORDER_FAILURE',
                    data: errorMessage
                })
            })
    }

}
export function getOrderByDelivery(filterObj) {
    let patameter = "order-by-delivery";
    if(filterObj){
        patameter = `order-by-delivery?deliveryStartDate=`+filterObj.StartDate+
        `&deliveryEndDate=`+filterObj.EndDate+
        (filterObj.TeamID ? `&teamId=`+filterObj.TeamID : "")+
        (filterObj.DeliveryStatus ? `&deliveryStatusId=`+filterObj.DeliveryStatus : "")+
        (filterObj.CustomerID ? `&customerId=`+filterObj.CustomerID : "")+
        (filterObj.SellerType ? `&sellerType=`+filterObj.SellerType : "");
    }
    return  returnApiCallPromise("GET", patameter)
    .then(response => {  
       return  {error : false, message:"success", orders : response.data.data}
    }).catch(error => {
        let errorMessage = error;
        if(error.response){
            if(error.response.data.error){
                errorMessage = error.response.data;
            }
        } 
        return  {error : true, message: errorMessage.message, orders : []}
    })
}
export function AdhocScheduleDelivery(AddObject) {
   
    let subURL = "schedule-delivery";
    return (dispatch) => {
        returnApiCallPromise("POST", subURL, AddObject)
            .then(response => { 
                if(response.data.error){
                    dispatch({
                        type: 'ADHOC_SCHEDULE_FAILURE',
                        data: response.data.message
                    })
                }else{
                    dispatch({
                        type: 'ADHOC_SCHEDULE_SUCCESS',
                        data: response.data
                    })
                } 
            }).catch(error => {  
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                } 
                dispatch({
                    type: 'ADHOC_SCHEDULE_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function getSeller() {
    let patameter = "seller-info";
    return (dispatch) => {
        dispatch({ type: 'FETCHING_SELLER' });
        returnApiCallPromise("GET", patameter)
            .then(response => {            
                dispatch({
                    type: 'SELLER_SUCCESS',
                    data: response.data.data
                })
            }).catch(error => {
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                } 
                dispatch({
                    type: 'SELLER_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function SyncOrder(ReqObj) {
   
    let subURL = "sync-orders";
    return (dispatch) => {
        returnApiCallPromise("POST", subURL, ReqObj)
            .then(response => { 
                if(response.data.error){
                    dispatch({
                        type: 'SYNC_FAILURE',
                        data: response.data.message
                    })
                }else{
                    dispatch({
                        type: 'SYNC_SUCCESS',
                        data: response.data
                    })
                } 
            }).catch(error => { 
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                } 
                dispatch({
                    type: 'SYNC_FAILURE',
                    data: errorMessage
                })
            })
    }
}

export function UpdateSchedulesTeam(OrderID,UpdateObject) {
   
    let subURL = "schedule-delivery/"+OrderID;
    
    return (dispatch) => {
        returnApiCallPromise("PUT", subURL, UpdateObject)
            .then(response => { 
                if(response.data.error){
                    dispatch({
                        type: 'UPDATE_ORDER_TEAM_FAILURE',
                        data: response.data.message
                    })
                }else{
                    dispatch({
                        type: 'UPDATE_ORDER_TEAM_SUCCESS',
                        data: response.data
                    })
                } 
            }).catch(error => {  
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                } 
                dispatch({
                    type: 'UPDATE_ORDER_TEAM_FAILURE',
                    data: errorMessage
                })
            })
    }
}

export function UpdateOrderStatus(UpdateObject) {
   
    let subURL = "delivery-status";
   
    return (dispatch) => {
        returnApiCallPromise("FORM", subURL, UpdateObject)
            .then(response => { 
                if(response.data.error){
                    dispatch({
                        type: 'STATUS_UPDATE_FAILURE',
                        data: response.data.message
                    })
                }else{
                    dispatch({
                        type: 'STATUS_UPDATE_SUCCESS',
                        data: response.data
                    })
                }  
            }).catch(error => { 
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                } 
                dispatch({
                    type: 'STATUS_UPDATE_FAILURE',
                    data: errorMessage.message
                })
            })
    }
}
export function RestoreDelivery(ID) {
   
    let subURL = "schedule-delivery/"+ID;
   
    return (dispatch) => {
        returnApiCallPromise("DELETE", subURL, null)
            .then(response => { 
                if(response.data.error){
                    dispatch({
                        type: 'RESTORE_DELIVERY_FAILURE',
                        data: response.data
                    })
                }else{
                    dispatch({
                        type: 'RESTORE_DELIVERY_SUCCESS',
                        data: response.data
                    })
                }  
            }).catch(error => { 
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                } 
                dispatch({
                    type: 'RESTORE_DELIVERY_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function getPTripReport(filterObj) {
    let patameter = "order-ptrip-report";
    if(filterObj){
        patameter = `order-ptrip-report?`+
        (filterObj.DelStartDate ? `deliveryStartDate=`+filterObj.DelStartDate : "deliveryStartDate=")+
        (filterObj.DelEndDate ? `&deliveryEndDate=`+filterObj.DelEndDate : "")+
        (filterObj.DeliveryStatus ? `&deliveryStatusId=`+filterObj.DeliveryStatus : "")+
        (filterObj.TeamID ? `&teamID=`+filterObj.TeamID : "");
    }
    console.log(patameter)
    return (dispatch) => {
        dispatch({ type: 'FETCHING_PTRIP_REPORT' });
        returnApiCallPromise("GET", patameter)
            .then(response => {            
                dispatch({
                    type: 'PTRIP_REPORT_SUCCESS',
                    data: response.data.data
                })
            }).catch(error => {
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                } 
                dispatch({
                    type: 'PTRIP_REPORT_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function getCustomers(reqObject) {
    const subURL = "GetB2BRetailCustomer"
    return returnApiCallPromise("POST", subURL, reqObject, null, "b2b")
            .then(response => {  
                return {error : true, message : "success", data : response.data.customer}
            }).catch(error => {
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                } 
                return {error : true, message : errorMessage.message, data : []}
            })
}