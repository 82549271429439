export function GetCustomerProductReducer(state = {}, action) {    
    
    switch (action.type) {
        case 'CUST_PRODUCT_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                CustProduct: action.data
            }
        }
        case 'CUST_PRODUCT_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success:false,
                CustProduct: action.data
            }
        }
        case 'FETCHING_CUST_PRODUCT': {
            return {
                ...state,
                Success: false,
                Failure: false,
                CustProduct: action.data
            }
        }
        default: {
            return state;
        }
    }
}
export function GetServiceStatusReducer(state = {}, action) {    
    
    switch (action.type) {
        case 'SERVICE_STATUS_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                ServiceStatus: action.data
            }
        }
        case 'SERVICE_STATUS_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success:false,
                ServiceStatus: action.data
            }
        }
        case 'FETCHING_SERVICE_STATUS': {
            return {
                ...state,
                Success: false,
                Failure: false,
                ServiceStatus: action.data
            }
        }
        default: {
            return state;
        }
    }
}
export function GetServiceReducer(state = {}, action) {    
    
    switch (action.type) {
        case 'GET_SERVICE_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                Services: action.data
            }
        }
        case 'GET_SERVICE_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success:false,
                Services: action.data
            }
        }
        case 'FETCHING_GET_SERVICE': {
            return {
                ...state,
                Success: false,
                Failure: false,
                Services: action.data
            }
        }
        default: {
            return state;
        }
    }
}
export function GetTeamServiceReducer(state = {}, action) {    
    
    switch (action.type) {
        case 'GET_TEAM_SERVICE_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                TeamServices: action.data
            }
        }
        case 'GET_TEAM_SERVICE_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success:false,
                TeamServices: action.data
            }
        }
        case 'FETCHING_GET_TEAM_SERVICE': {
            return {
                ...state,
                Success: false,
                Failure: false,
                TeamServices: action.data
            }
        }
        default: {
            return {
                ...state,
                Failure: false,
                Success: false,
                TeamServices: null,
            }
        }
    }
}
export function AssignServiceReducer(state = {}, action) {    
    
    switch (action.type) {
        case 'ASSIGN_SERVICE_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                AssignServiceRsponse: action.data
            }
        }
        case 'ASSIGN_SERVICE_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success:false,
                AssignServiceRsponse: action.data
            }
        }
        default: {
            return {
                ...state,
                Failure: false,
                Success:false,
                AssignServiceRsponse: null,
            }
        }
    }
}
export function AddServiceReducer(state = {}, action) {
    switch (action.type) {
        case 'ADD_SERVICE_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                AddingResponse: action.data
            }
        }
        case 'ADD_SERVICE_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success:false,
                AddingResponse: action.data,
            }
        }
        default: {
            return {
                ...state,
                Failure: false,
                Success:false,
                AddingResponse: null,
            }
        }
    }
}
export function UpdateServiceReducer(state = {}, action) {
    switch (action.type) {
        case 'UPDATE_SERVICE_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                UpdateResponse: action.data
            }
        }
        case 'UPDATE_SERVICE_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success: false,
                UpdateResponse: action.data,
            }
        }
        default: {
            return {
                ...state,
                Failure: false,
                Success: false,
                UpdateResponse: null,
            };
        }
    }
}
export function DeleteServiceReducer(state = {}, action) {
    
    switch (action.type) {
       
        case 'DELETE_SERVICE_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                DeleteResponse: action.data
            }
        }
        case 'DELETE_SERVICE_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success: false,
                DeleteResponse: action.data,
            }
        }
        default: {
            return {
                ...state,
                Failure: false,
                Success: false,
                DeleteResponse: null,
            }
        }
    }
}
export function AddServiceDetailReducer(state = {}, action) {
    switch (action.type) {
        case 'ADD_SERVICE_DETAIL_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                AddingResponse: action.data
            }
        }
        case 'ADD_SERVICE_DETAIL_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success:false,
                AddingResponse: action.data,
            }
        }
        default: {
            return {
                ...state,
                Failure: false,
                Success:false,
                AddingResponse: null,
            }
        }
    }
}
export function GetAdhocServiceReducer(state = {}, action) {    
    
    switch (action.type) {
        case 'GET_ADHOC_SERVICE_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                AdhocServices: action.data
            }
        }
        case 'GET_ADHOC_SERVICE_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success:false,
                AdhocServices: action.data
            }
        }
        case 'FETCHING_GET_ADHOC_SERVICE': {
            return {
                ...state,
                Success: false,
                Failure: false,
                AdhocServices: action.data
            }
        }
        default: {
            return state;
        }
    }
}
export function AddAdhocServiceReducer(state = {}, action) {
    switch (action.type) {
        case 'ADD_ADHOC_SERVICE_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                AddingResponse: action.data
            }
        }
        case 'ADD_ADHOC_SERVICE_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success:false,
                AddingResponse: action.data,
            }
        }
        default: {
            return {
                ...state,
                Failure: false,
                Success:false,
                AddingResponse: null,
            }
        }
    }
}
export function UpdateAdhocServiceReducer(state = {}, action) {
    switch (action.type) {
        case 'UPDATE_ADHOC_SERVICE_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                UpdateResponse: action.data
            }
        }
        case 'UPDATE_ADHOC_SERVICE_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success: false,
                UpdateResponse: action.data,
            }
        }
        default: {
            return {
                ...state,
                Failure: false,
                Success: false,
                UpdateResponse: null,
            };
        }
    }
}
export function DeleteAdhocServiceReducer(state = {}, action) {
    
    switch (action.type) {
       
        case 'DELETE_ADHOC_SERVICE_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                DeleteResponse: action.data
            }
        }
        case 'DELETE_ADHOC_SERVICE_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success: false,
                DeleteResponse: action.data,
            }
        }
        default: {
            return {
                ...state,
                Failure: false,
                Success: false,
                DeleteResponse: null,
            }
        }
    }
}
export function GetJobTypesReducer(state = {}, action) {    
    
    switch (action.type) {
        case 'JOB_TYPES_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                JobTypes: action.data
            }
        }
        case 'JOB_TYPES_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success:false,
                JobTypes: action.data
            }
        }
        case 'FETCHING_JOB_TYPES': {
            return {
                ...state,
                Success: false,
                Failure: false,
                JobTypes: action.data
            }
        }
        default: {
            return state;
        }
    }
}
export function AddJobTypesReducer(state = {}, action) {
    switch (action.type) {
        case 'ADD_JOB_TYPE_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                AddingResponse: action.data
            }
        }
        case 'ADD_JOB_TYPE_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success:false,
                AddingResponse: action.data,
            }
        }
        default: {
            return {
                ...state,
                Failure: false,
                Success:false,
                AddingResponse: null,
            }
        }
    }
}
export function UpdateJobTypesReducer(state = {}, action) {
    switch (action.type) {
        case 'UPDATE_JOB_TYPE_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                UpdateResponse: action.data
            }
        }
        case 'UPDATE_JOB_TYPE_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success: false,
                UpdateResponse: action.data,
            }
        }
        default: {
            return {
                ...state,
                Failure: false,
                Success: false,
                UpdateResponse: null,
            };
        }
    }
}
export function DeleteJobTypesReducer(state = {}, action) {
    
    switch (action.type) {
       
        case 'DELETE_JOB_TYPE_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                DeleteResponse: action.data
            }
        }
        case 'DELETE_JOB_TYPE_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success: false,
                DeleteResponse: action.data,
            }
        }
        default: {
            return {
                ...state,
                Failure: false,
                Success: false,
                DeleteResponse: null,
            }
        }
    }
}
export function GetPaymentStatusReducer(state = {}, action) {    
    
    switch (action.type) {
        case 'PAYMENT_STATUS_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                PaymentStatus: action.data
            }
        }
        case 'PAYMENT_STATUS_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success:false,
                PaymentStatus: action.data
            }
        }
        case 'FETCHING_PAYMENT_STATUS': {
            return {
                ...state,
                Success: false,
                Failure: false,
                PaymentStatus: action.data
            }
        }
        default: {
            return state;
        }
    }
}
export function GetPaymentTypesReducer(state = {}, action) {    
    
    switch (action.type) {
        case 'PAYMENT_TYPES_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                PaymentTypes: action.data
            }
        }
        case 'PAYMENT_TYPES_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success:false,
                PaymentTypes: action.data
            }
        }
        case 'FETCHING_PAYMENT_TYPES': {
            return {
                ...state,
                Success: false,
                Failure: false,
                PaymentTypes: action.data
            }
        }
        default: {
            return state;
        }
    }
}
export function AddPaymentTypesReducer(state = {}, action) {
    switch (action.type) {
        case 'ADD_PAYMENT_TYPE_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                AddingResponse: action.data
            }
        }
        case 'ADD_PAYMENT_TYPE_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success:false,
                AddingResponse: action.data,
            }
        }
        default: {
            return {
                ...state,
                Failure: false,
                Success:false,
                AddingResponse: null,
            }
        }
    }
}
export function UpdatePaymentTypesReducer(state = {}, action) {
    switch (action.type) {
        case 'UPDATE_PAYMENT_TYPE_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                UpdateResponse: action.data
            }
        }
        case 'UPDATE_PAYMENT_TYPE_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success: false,
                UpdateResponse: action.data,
            }
        }
        default: {
            return {
                ...state,
                Failure: false,
                Success: false,
                UpdateResponse: null,
            };
        }
    }
}
export function DeletePaymentTypesReducer(state = {}, action) {
    
    switch (action.type) {
       
        case 'DELETE_PAYMENT_TYPE_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                DeleteResponse: action.data
            }
        }
        case 'DELETE_PAYMENT_TYPE_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success: false,
                DeleteResponse: action.data,
            }
        }
        default: {
            return {
                ...state,
                Failure: false,
                Success: false,
                DeleteResponse: null,
            }
        }
    }
}
export function GetTimeSlotsReducer(state = {}, action) {    
    
    switch (action.type) {
        case 'TIME_SLOTS_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                TimeSlots: action.data
            }
        }
        case 'TIME_SLOTS_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success:false,
                TimeSlots: action.data
            }
        }
        case 'TIME_SLOTS_TYPES': {
            return {
                ...state,
                Success: false,
                Failure: false,
                TimeSlots: null
            }
        }
        default: {
            return state;
        }
    }
}
export function AddTimeSlotsReducer(state = {}, action) {
    switch (action.type) {
        case 'ADD_TIME_SLOTS_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                AddingResponse: action.data
            }
        }
        case 'ADD_TIME_SLOTS_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success:false,
                AddingResponse: action.data,
            }
        }
        default: {
            return {
                ...state,
                Failure: false,
                Success:false,
                AddingResponse: null,
            }
        }
    }
}
export function UpdateTimeSlotsReducer(state = {}, action) {
    switch (action.type) {
        case 'UPDATE_TIME_SLOTS_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                UpdateResponse: action.data
            }
        }
        case 'UPDATE_TIME_SLOTS_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success: false,
                UpdateResponse: action.data,
            }
        }
        default: {
            return {
                ...state,
                Failure: false,
                Success: false,
                UpdateResponse: null,
            };
        }
    }
}
export function DeleteTimeSlotsReducer(state = {}, action) {
    
    switch (action.type) {
       
        case 'DELETE_TIME_SLOTS_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                DeleteResponse: action.data
            }
        }
        case 'DELETE_TIME_SLOTS_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success: false,
                DeleteResponse: action.data,
            }
        }
        default: {
            return {
                ...state,
                Failure: false,
                Success: false,
                DeleteResponse: null,
            }
        }
    }
}
export function GetTimeSlotReportReducer(state = {}, action) {    
    
    switch (action.type) {
        case 'TSLOT_REPORT_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                TSlotReport: action.data
            }
        }
        case 'TSLOT_REPORT_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success:false,
                TSlotReport: action.data
            }
        }
        case 'FETCHING_TSLOT_REPORT': {
            return {
                ...state,
                Success: false,
                Failure: false,
                TSlotReport: null
            }
        }
        default: {
            return state;
        }
    }
}
export function GetServiceFormsReducer(state = {}, action) {    
    
    switch (action.type) {
        case 'S_FORM_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                SForm: action.data
            }
        }
        case 'S_FORM_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success:false,
                SForm: action.data
            }
        }
        case 'FETCHING_S_FORM': {
            return {
                ...state,
                Success: false,
                Failure: false,
                SForm: null
            }
        }
        default: {
            return state;
        }
    }
}
export function GetFilledServiceFormsReducer(state = {}, action) {    
    
    switch (action.type) {
        case 'FS_FORM_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                FSForm: action.data
            }
        }
        case 'FS_FORM_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success:false,
                FSForm: action.data
            }
        }
        case 'FETCHING_FS_FORM': {
            return {
                ...state,
                Success: false,
                Failure: false,
                FSForm: null
            }
        }
        default: {
            return state;
        }
    }
}
export function GetServiceReportReducer(state = {}, action) {    
    
    switch (action.type) {
        case 'SERVICE_REPORT_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                SReport: action.data
            }
        }
        case 'SERVICE_REPORT_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success:false,
                SReport: action.data
            }
        }
        case 'FETCHING_SERVICE_REPORT': {
            return {
                ...state,
                Success: false,
                Failure: false,
                SReport: null
            }
        }
        default: {
            return state;
        }
    }
}
export function ExportCustomersReducer(state = {}, action) {    
    
    switch (action.type) {
        case 'UPLOAD_CUSTOMER_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                ExportResponse: action.data
            }
        }
        case 'UPLOAD_CUSTOMER_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success:false,
                ExportResponse: action.data
            }
        }
        default: {
            return {
                ...state,
                Failure: false,
                Success: false,
                ExportResponse: null,
            }
        }
    }
}
export function GetSellnchillReducer(state = {}, action) {    
    
    switch (action.type) {
        case 'S_TOKEN_SUCCESS': {
            return {
                ...state,
                STokenSuccess: true,
                STokenFailure: false,
                SToken: action.data
            }
        }
        case 'S_TOKEN_FAILURE': {
            return {
                ...state,
                STokenFailure: true,
                STokenSuccess:false,
                SToken: action.data
            }
        }
        case 'CUST_ORDERS_SUCCESS': {
            return {
                ...state,
                SOrdersSuccess: true,
                SOrdersFailure: false,
                SOrders: action.data
            }
        }
        case 'CUST_ORDERS_FAILURE': {
            return {
                ...state,
                SOrdersSuccess: false,
                SOrdersFailure: true,
                SOrders: action.data
            }
        }
        case 'CUST_ORDERS_DETAIL_SUCCESS': {
            return {
                ...state,
                SOrdersDetailSuccess: true,
                SOrdersDetailFailure: false,
                SOrdersDetail: action.data
            }
        }
        case 'CUST_ORDERS_DETAIL_FAILURE': {
            return {
                ...state,
                SOrdersDetailSuccess: false,
                SOrdersDetailFailure: true,
                SOrdersDetail: action.data
            }
        }
        default: {
            return {
                ...state,
                SOrdersDetailSuccess: false,
                SOrdersDetailFailure: false,
                SOrdersDetail: null,
                STokenFailure: false,
                STokenSuccess:false,
                SToken: null,
                SOrdersSuccess: false,
                SOrdersFailure: false,
                SOrders: null
            }
        }
    }
}