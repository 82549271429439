import React from "react";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import { classnames } from "./../../utilities/PackagesList";

// styles
import useStyles from "./styles";

// components
import Header from "./../Header";
import Sidebar from "./../Sidebar";

// Common
import Staffs from "./../../pages/staffs";
import Vehicles from "./../../pages/vehicles";
import Teams from "./../../pages/teams";
//Delivery
import Delivery from "./../../pages/delivery";
import AdhocDelivery from "./../../pages/adhocdelivery";
import AdhocSchedule from "./../../pages/adhocschedule";
import DeliveryStatus from "./../../pages/deliverystatus";
import DeliveryReport from "./../../pages/deliveryreport";
import prodReport from "./../../pages/productreport";
// context
import { useLayoutState } from "./../../context/LayoutContext";

function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} />
        <Sidebar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <div className={classes.fakeToolbar} />
          <Switch>
            <Route exact path="/app/vehicles" component={Vehicles} />
            <Route exact path="/app/staffs" component={Staffs} />
            <Route exact path="/app/teams" component={Teams} />
            <Route exact path="/app/delivery" component={Delivery} />
            <Route exact path="/app/adhocdelivery" component={AdhocDelivery} />
            <Route exact path="/app/adhocschedule" component={AdhocSchedule} />
            <Route exact path="/app/deliverystatus" component={DeliveryStatus} />
            <Route exact path="/app/deliveryreport" component={DeliveryReport} />
            <Route exact path="/app/productreport" component={prodReport} />
          </Switch>
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);
