import React, { useState, useEffect, Component } from "react";
import {
  Grid, TextField, Button, Switch,
  FormControlLabel, IconButton, DeleteIcon,
  EditIcon, MUIDataTable, Autocomplete, connect,
  _, swal, toast, Typography, Checkbox, Tab,
  TabPanel, AppBar, TabContext, TabList, withStyles,
  CardMedia, SRLWrapper, SimpleReactLightbox
} from "../../utilities/PackagesList";
import {
  PageTitle, CustomToolbar, AlertDialog,
  CustomModel, InputField, Dropdown,
  Buttons, LoadingOverlay, ButtonIcon
} from "../../utilities/ComponentsList";
import {
  isEmptyObj, dateTimeFormating, todayDate,
  LastDayMonth, DateMinusYMD, AMPM, LowerCase,
  convertDateDMY, DMY, PriceFormat, DecimalFormat, UnChangeObj,
  GetFullMobileNo, DMYD, CheckSame, buildSMS, nullToStr
} from "../../utilities/AppFunctions";
import { getAdhoc, RestoreDelivery, UpdateOrderStatus } from '../../api/Adhoc';
import { getTeams } from '../../api/Team';
import { SendSMS } from '../../api/Notification';
import { refreshReduxStates } from '../../api/Refresh';
import { LoaderStatus } from '../../api/Loading';
import { DeliveryList, MockDatas } from "../../utilities/MockData";
import AssignTeam from "./AssignTeam";
import EditTeam from "./EditTeam";
import EditOrder from "./EditOrder";
import ViewList from "./ViewList";
import styles from "../../utilities/StylesClass";
import BaseDetails from './../../utilities/BaseDetails.json';
import IMLocalize from "utilities/IMLocalize";
let getAdhocState = false;
class DeliveryStatus extends Component {

  constructor(props) {
    super(props);
    this.state = {
      DialogState: false,
      RestoreState: false,
      CancelState: false,
      CancelID: null,
      RestoreID: null,
      DeleteID: null,
      EditID: null,
      SelectedIndex: null,
      ModelType: null,
      ModelState: false,
      EditModelState: false,
      AdhocLists: [],
      ReadyList: [],
      ProgressList: [],
      UnDeliverList: [],
      CompletedList: [],
      UnFilteredList: [],
      FilteredList: [],
      StartDate: DateMinusYMD(5),
      EndDate: todayDate(),
      FilterName: "",
      FilterMobile: "",
      FilterOrderNo: "",
      PostalCode: "",
      isLoading: true,
      getCalls: [1, 1],
      postCalls: [0, 0],
      TabSelected: "1",
      TeamsLists: [],
      TeamSelected: null,
      Team: null,
      signColumn: false,
      Filter2ListTypes: [{ id: 1, name: "Pick List" }],
      Filter2ListID: "",
      Filter2ListSelected: "",
      SelectedOrders: [],
      AssignTeamState: false,
      EditTeamState: false,
      EditOrderState: false,
      SelectingList:[],
      ListModel: false,
      ViewID: null
    }
    let filterObj = {
      StartDate: DateMinusYMD(5),
      EndDate: todayDate(),
      OrderType: null,
      DeliveryStatus: 1,
      sellerType: null
    }
    props.getAdhoc(filterObj);
    props.getTeams(2);

  }

  UNSAFE_componentWillReceiveProps = (props) => {
    if (this.state.getCalls[0]) {
      if (props.AdhocListSuccess) {
        let succed = this.state.getCalls;
        succed[0] = 0;
        let loaderStatus = getAdhocState ? false : succed.includes(1);

        getAdhocState = false;
        let NormalDeliveries = props.AdhocList;
        if(this.state.TabSelected=='5'){
          NormalDeliveries = _.orderBy(NormalDeliveries, ['updatedAt'], ['asc']);
        }else{
          NormalDeliveries = _.orderBy(NormalDeliveries, ['deliveryDate', 'deliveryTime'], ['asc', 'asc']);
        }
        let DeliveryStatus = this.getStatus(this.state.TabSelected);
        /* let readyItems = _.filter(NormalDeliveries, function (item) {
          return todayDate() == item.deliveryDate
            && item.deliveryStatus == DeliveryStatus;
        });
        console.log(JSON.stringify(NormalDeliveries)) */
        let postalSelected = this.state.PostalCode;
        let TeamSelectedID = this.state.TeamSelected;
        let NameSelected = this.state.FilterName;
        let MobileSelected = this.state.FilterMobile;
        let OrderNoSelected = this.state.FilterOrderNo;
        let FilteredItems = _.filter(NormalDeliveries, function (item) {
          return (postalSelected ? (LowerCase(postalSelected) == LowerCase(item.postalCode)) : true)
            && (NameSelected ? (LowerCase(NameSelected) == LowerCase(item.customerName)) : true)
            && (MobileSelected ? (LowerCase(MobileSelected) == LowerCase(item.contactNumber)) : true)
            && (OrderNoSelected ? (LowerCase(OrderNoSelected) == LowerCase(item.orderNumber)) : true)
            && (TeamSelectedID ? (TeamSelectedID == item.teamId) : true);
        });
        this.setState({
          AdhocLists: NormalDeliveries,
          UnFilteredList: NormalDeliveries,
          FilteredList: FilteredItems,
          ReadyList: [],
          ProgressList: [],
          UnDeliverList: [],
          CompletedList: [],
          isLoading: loaderStatus,
          getCalls: succed
        })
      } else if (props.AdhocListFailure) {
        let succed = this.state.getCalls;
        succed[0] = 0;
        let loaderStatus = getAdhocState ? false : succed.includes(1);
        getAdhocState = false;
        this.setState({
          AdhocLists: [],
          ReadyList: [],
          ProgressList: [],
          UnDeliverList: [],
          CompletedList: [],
          getCalls: succed,
          isLoading: loaderStatus
        })
      }
    }
    if (this.state.getCalls[1]) {
      if (props.TeamListSuccess) {
        let succed = this.state.getCalls;
        succed[1] = 0;
        let loaderStatus = succed.includes(1);
        let newTeam = {
          "id": 0,
          "teamName": "All",
          "vehicleNumber": "",
          "vehicelId": "",
          "assignedStaff": [],
          "teamTypeId": 0,
          "teamTypeName": "all"
        };
        let Teams = props.TeamLists;
        Teams.unshift(newTeam)
        this.setState({
          TeamsLists: props.TeamLists,
          isLoading: loaderStatus,
          TeamSelected: Teams[0].id,
          Team: Teams[0],
          getCalls: succed
        })
      } else if (props.TeamListFailure) {
        let succed = this.state.getCalls;
        succed[1] = 0;
        let loaderStatus = succed.includes(1);
        this.setState({
          TeamsLists: [],
          getCalls: succed,
          isLoading: loaderStatus
        })
      }
    }
    if (this.state.postCalls[0]) {
      if (props.OrderUpdateSuccess) {
        let SelCancelID = this.state.CancelID;
        let listData = this.state.FilteredList;
        let ItemsSelected = _.filter(listData, function (item) {
          return SelCancelID == item.id ? false : true;
        });
        this.setState({
          postCalls: [0, 0],
          isLoading: false,
          CancelID: null,
          CancelState: false,
          FilteredList: ItemsSelected,
        }, () => {
          this.props.refreshReduxStates();
          toast.success('Updated Successfully!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
          });
        })
      } else if (props.OrderUpdateFailure) {
        this.setState({
          postCalls: [0, 0],
          isLoading: false,
          CancelID: null,
          CancelState: false
        }, () => {
          toast.error(props.OrderUpdateResponse.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
          });
        })
      }
    }
    if (this.state.postCalls[1]) {
      if (props.DeleteDeliverySuccess) {
        let RestoreIDVal = this.state.RestoreID;
        let listData = this.state.FilteredList;
        let ItemsSelected = _.filter(listData, function (item) {
          return RestoreIDVal == item.id ? false : true;
        });
        this.setState({
          postCalls: [0, 0],
          isLoading: false,
          RestoreID: null,
          RestoreState: false,
          FilteredList: ItemsSelected,
        }, () => {
          this.props.refreshReduxStates();
          toast.success('Updated Successfully!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
          });
        })
      } else if (props.DeleteDeliveryFailure) {
        this.setState({
          postCalls: [0, 0],
          isLoading: false,
          RestoreID: null,
          RestoreState: false,
        }, () => {
          toast.error(props.DeleteDeliveryResponse.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
          });
        })
      }
    }
  }
  handleChange = (event, newValue) => {
    let filterObj = {
      StartDate: newValue=="5" ? "" : DateMinusYMD(5),
      EndDate: newValue=="5" ? "" : todayDate(),
      OrderStartDate : newValue=="5" ? DateMinusYMD(5) : "",
      OrderEndDate : newValue=="5" ? todayDate() : "",
      OrderType: null,
      DeliveryStatus: parseInt(newValue),
      sellerType: null
    }
    this.setState({
      //FilteredList: ItemsSelected,
      TabSelected: newValue,
      StartDate: DateMinusYMD(5),
      EndDate: todayDate(),
      PostalCode: "",
      FilterName: "",
      FilterMobile: "",
      FilterOrderNo: "",
      TeamSelected: null,
      Team: null,
      signColumn: newValue == "4" ? true : false,
      getCalls: [1, 0],
      isLoading: true
    }, () => {
      this.props.getAdhoc(filterObj);
    })

  }
  getStatus = (tabVal) => {
    if (tabVal == "1") {
      return "Ready To Deliver";
    } else if (tabVal == "2") {
      return "In Progress";
    } else if (tabVal == "3") {
      return "Undelivered";
    } else if (tabVal == "4") {
      return "Completed";
    } else if (tabVal == "5") {
      return "Canceled";
    }
  }
  searchClick = () => {
    if (!this.state.StartDate && !this.state.EndDate && !this.state.PostalCode
      && !this.state.TeamSelected && !this.state.FilterName && !this.state.FilterMobile && !this.state.FilterOrderNo) {
      toast.warning('Please choose any one filter option!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: false,
      });
      return;
    }
    let StartdatePicked = this.state.StartDate ? this.state.StartDate : DateMinusYMD(5);
    let EnddatePicked = this.state.EndDate ? this.state.EndDate : todayDate();
    let filterObj = {
      StartDate: this.state.TabSelected=="5" ? "" : StartdatePicked,
      EndDate: this.state.TabSelected=="5" ? "" : EnddatePicked,
      OrderStartDate : this.state.TabSelected=="5" ? StartdatePicked : "",
      OrderEndDate : this.state.TabSelected=="5" ? EnddatePicked : "",
      OrderType: null,
      DeliveryStatus: parseInt(this.state.TabSelected),
      sellerType: null
    }

    this.setState({
      //FilteredList: ItemsSelected,
      getCalls: [1, 0],
      isLoading: true
    }, () => {
      this.props.getAdhoc(filterObj);
    })

  }
  //ASSIGN TEAM END
  checkedItemsChange = (e, value, tableMeta, updateValue) => {
    let selectedItems = this.state.SelectedOrders;
    let Arrayindex = selectedItems.indexOf(value);
    if (selectedItems.includes(value)) {
      if (Arrayindex > -1) {
        selectedItems.splice(Arrayindex, 1);
      }
    } else {
      selectedItems.push(value);
    }

    this.setState({
      SelectedOrders: selectedItems
    });
  }
  clearSearchClick = () => {
    let DeliveryStatus = this.state.TabSelected ? this.state.TabSelected : 1;
    let filterObj = {
      StartDate: this.state.TabSelected=="5" ? "" : DateMinusYMD(5),
      EndDate: this.state.TabSelected=="5" ? "" : todayDate(),
      OrderStartDate : this.state.TabSelected=="5" ? DateMinusYMD(5) : "",
      OrderEndDate : this.state.TabSelected=="5" ? todayDate() : "",
      OrderType: null,
      DeliveryStatus: DeliveryStatus,
      sellerType: null
    }
    this.setState({
      //FilteredList: LIsts,
      StartDate: DateMinusYMD(5),
      EndDate: todayDate(),
      PostalCode: "",
      FilterName: "",
      FilterMobile: "",
      FilterOrderNo: "",
      TeamSelected: null,
      Team: null,
      getCalls: [1, 0],
      isLoading: true
    }, () => {
      this.props.getAdhoc(filterObj);
    });

  }
  ScheduleDeleviryClick = () => {
    if (this.state.SelectedOrders.length > 0) {
      this.setState({
        AssignTeamState: true
      })
    } else {
      toast.warning('Please choose order!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: false,
      });
    }
  }
  //ASSIGN TEAM
  AssignedTeam = (AddedObj) => {
    let ListData = this.state.UnFilteredList;
    let FListData = this.state.FilteredList;
    let selectedOrd = this.state.SelectedOrders;
    let smsObj = [];
    const propsData = this.props;
    let newList = FListData.filter(function (value, index) {
      let orderExist = selectedOrd.includes(value.id);
      if (orderExist) {
        var Arrayindex = selectedOrd.indexOf(value.id);
        selectedOrd.splice(Arrayindex, 1);
        value.deliveryStatusId = 1;
        value.deliveryStatus = "Ready To Deliver";
        value.teamId = AddedObj.teamId;
        value.teamName = AddedObj.teamName;
        value.deliveryDate = AddedObj.date;
        value.deliveryTime = AddedObj.time;
        value.pTrip = AddedObj.pTrip;
        if(value.contactNumber){
          smsObj = buildSMS(AddedObj.date, value.contactNumber, value.amount, "reassigned");
           /*  smsObj.push(
              {
                "type": "sms",
                "body": "Thank you for ordering from AFN. Your order will be delivered on "+DMYD(AddedObj.date)+" between 9.30am to 5.30pm. Please do not reply. System generated message.",
                "toMobileNo": GetFullMobileNo(value.contactNumber)
              }
            ) */
        }
      }
      return value;
    })

    /*  let ItemsSelected = _.filter(newList, function (item) {
       return todayDate() == item.deliveryDate
         && item.deliveryStatus == "Undelivered";
     }); */
    this.setState({
      AssignTeamState: false,
      SelectedOrders: [],
      //UnFilteredList: newList,
      FilteredList: newList,
    }, () => {
      //this.props.refreshReduxStates();
      if(smsObj.length>0){
        this.props.SendSMS(smsObj);
      }else{
        this.props.refreshReduxStates();
      }
      
    })
  }
  CloseAssign = () => {
    this.setState({
      AssignTeamState: false,
      SelectedOrders: [],
      SelectedOrderIndex: []
    })
  }
  editPress = (id, index) => {
    let FListData = UnChangeObj(this.state.FilteredList);
    this.setState({
      EditTeamState: true,
      EditID: id,
      SelectingList: FListData
    })
  }
  CloseEditTeam = () => {
    this.setState({
      EditTeamState: false,
      SelectingList : []
    })
  }
  EditedTeam = (AddedObj) => {
    let FListData = this.state.FilteredList;
    let EditedID = this.state.EditID;
    let smsObj = [];
    let newList = FListData.filter(function (value, index) {
      let orderExist = value.id === EditedID;
      if (orderExist) {
        let existDeliveryDate = value.deliveryDate;
        value.teamId = AddedObj.teamId;
        value.teamName = AddedObj.teamName;
        value.deliveryDate = AddedObj.date;
        value.deliveryTime = AddedObj.time;
        value.pTrip = AddedObj.pTrip;
        if(value.contactNumber && !CheckSame(existDeliveryDate,AddedObj.date)){
          smsObj.push(
            {
              "type": "sms",
              "body": "Thank you for ordering from AFN. Your order will be delivered on "+DMYD(AddedObj.date)+" between 9.30am to 5.30pm. Please do not reply. System generated message.",
              "toMobileNo": GetFullMobileNo(value.contactNumber)
            }
          )
      }
      }
      return value;
    })

    /* let ItemsSelected = _.filter(newList, function (item) {
      return (item.deliveryDate >= selStartDate && item.deliveryDate <= selEndDate)
        && item.deliveryStatus == "Ready To Deliver";
    }); */
    this.setState({
      EditTeamState: false,
      SelectedOrders: [],
      EditedID: null,
      FilteredList: newList,
      SelectingList : []
    }, () => {
      if(smsObj.length>0){
        this.props.SendSMS(smsObj);
      }else{
        this.props.refreshReduxStates();
      }
    })
  }
  EditedOrder = (EditedObject) => {
    let ListData = this.state.UnFilteredList;
    let FListData = this.state.FilteredList;
    let EditedID = this.state.EditID;
    let selStartDate = this.state.StartDate;
    let selEndDate = this.state.EndDate;
    let newList = FListData.filter(function (value, index) {
      let orderExist = value.id === EditedID;
      if (orderExist) {
        value.contactNumber = EditedObject.customerNumber;
        value.customerName = EditedObject.customerName;
        value.address = EditedObject.address;
        value.postalCode = EditedObject.postalCode;
        value.items = EditedObject.items;
        value.remark = EditedObject.remark;
      }
      return value;
    })

    /* let ItemsSelected = _.filter(newList, function (item) {
      return (item.deliveryDate >= selStartDate && item.deliveryDate <= selEndDate)
        && item.deliveryStatus == "Ready To Deliver";
    }); */
    this.setState({
      EditOrderState: false,
      SelectedOrders: [],
      //UnFilteredList: newList,
      EditedID: null,
      SelectingList : [],
      FilteredList: newList,
    }, () => {
      this.props.refreshReduxStates();
    })
  }
  editOrderPress = (id, index) => {
    let ListData = UnChangeObj(this.state.FilteredList);
    this.setState({
      EditOrderState: true,
      EditID: id,
      SelectingList : ListData
    })
  }
  CloseOrderEdit = () => {
    this.setState({
      EditOrderState: false,
      SelectingList : []
    })
  }
  CanceledConfirm = () => {
    let formData = new FormData();
    formData.append('deliveryStatusId', 2);
    formData.append('orderId', this.state.CancelID);
    this.setState({
      postCalls: [1, 0],
      isLoading: true
    }, () => {
      this.props.UpdateOrderStatus(formData);
    })
  }
  CanceledClose = () => {
    this.setState({
      CancelState: false,
      CancelID: null,
    })
  }
  CancelOrder = (id, index) => {
    this.setState({
      CancelState: true,
      CancelID: id,
    })
  }
  RestoreConfirm = () => {
    this.setState({
      postCalls: [0, 1],
      isLoading: true
    }, () => {
      this.props.RestoreDelivery(this.state.RestoreID);
    })
  }
  RestoreClose = () => {
    this.setState({
      RestoreState: false,
      RestoreID: null,
    })
  }
  RestoreOrder = (id, index) => {
    if(this.state.TabSelected == '4'){
      this.setState({
        CancelState: true,
        CancelID: id,
      })
    }else{
      this.setState({
        RestoreState: true,
        RestoreID: id,
      })
    }
    
  }
  //ASSIGN TEAM END
  viewPress = (id, index) => {
    this.setState({
      ListModel: true,
      ViewID: id
    })
  }
  CloseList = () => {
    this.setState({
      ListModel: false,
      ViewID: ""
    })
  }
  render() {
    const { classes } = this.props;
    const Lightoptions = {
      settings: {
        overlayColor: "rgba(0, 0, 0, 0.9)",
        autoplaySpeed: 1500,
        transitionSpeed: 900,
        hideControlsAfter: true
      }

    };
    const columns = [
      {
        name: "id",
        label: IMLocalize().select,
        options: {
          sort: false,
          filter: false,
          print: this.state.TabSelected == "3" ? true : false,
          display: this.state.TabSelected == "3" ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Grid item md={12}>
                <Checkbox
                  checked={this.state.SelectedOrders ? (this.state.SelectedOrders).includes(value) : false}
                  onChange={(e) => this.checkedItemsChange(e, value, tableMeta, updateValue)}
                  name="checkedB"
                  color="primary"
                  size="small"
                />
              </Grid>
            )
          }
        }
      },
      {
        name: "orderId",
        label: "Order ID",
        options: {
          filter: false,
          sort: true,
          display: false,
          print: false
        }
      },
      {
        name: "orderNumber",
        label: IMLocalize().orderNumber,
        options: {
          filter: false,
          sort: true,
          display: true,
          print: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            let OrdNumb = value ? value : tableMeta.rowData[1];
            return (
              <Grid item md={12}>
                <Typography>{OrdNumb}</Typography>
              </Grid>
            )
          }
        }
      },
      {
        name: "customerInvoiceNumber",
        label: IMLocalize().customInvoice,
        options: {
          filter: false,
          sort: true,
          display: true,
          print: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return nullToStr(value)
          }
        }
      },
      {
        name: "customerName",
        label: IMLocalize().customerName,
        options: {
          filter: false,
          sort: true,
          display: true,
          print: true,
        }
      },
      {
         name: "address",
         label: IMLocalize().address,
         options: {
           filter: false,
           sort: false,
         }
       },
      {
        name: "contactNumber",
        label: IMLocalize().contactNo,
        options: {
          filter: true,
          sort: true,
          display: true,
          print: true,
        }
      },
      {
        name: "deliveryTime",
        label: IMLocalize().deliveryTime,
        options: {
          filter: false,
          sort: false,
          display: false,
          print: false
        }
      },
      {
        name: "postalCode",
        label: IMLocalize().postalCode,
        options: {
          filter: true,
          sort: true,
          display: true,
          print: true
        }
      },
      {
        name: "createdAt",
        label: IMLocalize().orderDate,
        options: {
          filter: true,
          sort: true,
          display: this.state.TabSelected == "5" ? true : false,
          print: this.state.TabSelected == "5" ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Grid item md={12}>
                <Typography>{convertDateDMY(value)}</Typography>
              </Grid>
            )
          }
        }
      },
      {
        name: "deliveryDate",
        label: IMLocalize().deliverDate,
        options: {
          filter: true,
          sort: true,
          display: this.state.TabSelected != "5" ? true : false,
          print: this.state.TabSelected != "5" ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Grid item md={12}>
                <Typography>{convertDateDMY(value)}</Typography>
                <Typography>{AMPM(tableMeta.rowData[7])}</Typography>
              </Grid>
            )
          }
        }
      },
      {
        name: "pTrip",
        label: IMLocalize().pTrip,
        options: {
          filter: false,
          sort: false,
          display: false,// this.state.TabSelected != "5" ? true : false,
          print: false,//this.state.TabSelected != "5" ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => {
            let inptData = value == 'undefined' ? 0 : value;
            return (
              <Grid item md={12}>
                <Typography>{DecimalFormat(inptData)}</Typography>
              </Grid>
            )
          }
        }
      },
      {
        name: "completedAt",
        label: IMLocalize().completedDate,
        options: {
          filter: false,
          sort: true,
          print: this.state.signColumn,
          display: this.state.signColumn,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Grid item lg={12}>
                <Typography>{dateTimeFormating(value)}</Typography>
              </Grid>
            )
          }
        }
      },
      {
        name: "updatedAt",
        label: IMLocalize().canceledDate,
        options: {
          filter: false,
          sort: true,
          print: this.state.TabSelected == "5" ? true : false,
          display: this.state.TabSelected == "5" ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Grid item lg={12}>
                <Typography>{dateTimeFormating(value)}</Typography>
              </Grid>
            )
          }
        }
      },
      {
        name: "orderDetails",
        label: IMLocalize().products,
        options: {
          filter: false,
          sort: false,
          setCellProps: () => ({ style: { minWidth: "20px", maxWidth: "30px" } }),
           customBodyRender: (value, tableMeta, updateValue) => { 
             return (
              <ButtonIcon
                ViewTitle={"View Items"}
                View={() => this.viewPress(tableMeta.rowData[0], tableMeta.rowIndex)}
              />
            )
          } 
        }
      },
      {
        name: "remark",
        label: IMLocalize().remarks,
        options: {
          filter: false,
          sort: false,
          display: true,
          print: true
          /* customBodyRender: (value, tableMeta, updateValue) => {
            let checkString = value.includes(",");
            let items = checkString ? value.split(",") : [value];
            return (
              <Grid item md={12}>
                {items.map((itemVal, index) => (
                  <Typography>{itemVal}</Typography>
                ))}

              </Grid>
            )
          } */
        }
      },
      {
        name: "images",
        label: IMLocalize().images,
        options: {
          filter: false,
          sort: false,
          print: this.state.signColumn,
          display: this.state.signColumn,
          setCellProps: () => ({ style: { minWidth: "100px" } }),
          customBodyRender: (value, tableMeta, updateValue) => {
            let validateData = value ? value : [];
            let URL = BaseDetails.baseURL;
            return (
              <Grid item md={12}>
                <SimpleReactLightbox>
                  <SRLWrapper options={Lightoptions}>
                    {validateData.map((itemVal, index) => (
                      <a href={URL + '' + itemVal}>
                        <img
                          src={URL + '' + itemVal}
                          alt={itemVal}
                          style={{
                            position: 'relative',
                            marginLeft: index == 0 ? index : index - 15,
                            width: 20, height: 20,
                            borderRadius: 20
                          }}
                        />
                      </a>
                    ))}
                  </SRLWrapper>
                </SimpleReactLightbox>
              </Grid>
            )
          }
        }
      },
      {
        name: "signature",
        label: IMLocalize().signature,
        options: {
          filter: false,
          sort: false,
          print: this.state.signColumn,
          display: this.state.signColumn,
          customBodyRender: (value, tableMeta, updateValue) => {
            let URL = BaseDetails.baseURL + '' + value;
            return (
              <Grid item md={12}>
                {value ?
                  <img src={URL} alt="Signature"
                    style={{
                      width: 100, height: 50
                    }}
                  />
                  : null}

                {/*  <CardMedia
                  component="img"
                  alt="Sign"
                  height="50"
                  //width="30"
                  image={URL}
                  title="Sign"
                /> */}

              </Grid>
            )
          }
        }
      },
      {
        name: "teamName",
        label: IMLocalize().teamName,
        options: {
          filter: true,
          sort: true,
          display: true,
          print: true
        }
      },
      {
        name: "",
        label: IMLocalize().actions,
        options: {
          filter: false,
          sort: false,
          print: false,
          display: true,//(this.state.TabSelected == "1" || this.state.TabSelected == "2" || this.state.TabSelected == "3") ? true : false,
          setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "100px" } }),
          customBodyRender: (value, tableMeta, updateValue) => {
            let SelectedStatus = this.state.TabSelected;
            let EditStatus = SelectedStatus == "1" ? true : false;
            let RestoreStatus = (SelectedStatus == "1" || SelectedStatus == "2" || SelectedStatus == "3") ? true : false;
            let CancelStatus = (SelectedStatus == "1" || SelectedStatus == "2" || SelectedStatus == "3") ? true : false;
            return (
              <ButtonIcon
                EditTitle={EditStatus ? "Edit Team" : null}
                EditTitle1={EditStatus ? "Edit Order" : null}
                CancelTitle={""}
                RestoreTitle={this.state.TabSelected == "4" ? "Move to Progress" : "Back to Marketplace/Adhoc"}
                Restore={() => this.RestoreOrder(tableMeta.rowData[0], tableMeta.rowIndex)}
                Cancel={() => this.CancelOrder(tableMeta.rowData[0], tableMeta.rowIndex)}
                Edit={() => this.editPress(tableMeta.rowData[0], tableMeta.rowIndex)}
                Edit1={() => this.editOrderPress(tableMeta.rowData[0], tableMeta.rowIndex)}
              />

            )
          }
        }
      }
    ];

    return (
      <>
        {/* <PageTitle title="" /> */}
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TabContext value={this.state.TabSelected}>
              <AppBar position="static" color="default">
                <TabList onChange={(event, newValue) => this.handleChange(event, newValue)} aria-label="">
                  <Tab label={IMLocalize().ready2Deliver} value="1" />
                  <Tab label={IMLocalize().inProgress} value="2" />
                  <Tab label={IMLocalize().unDelivered} value="3" />
                  <Tab label={IMLocalize().completed} value="4" />
                  {/* <Tab label="Canceled" value="5" /> */}
                </TabList>
              </AppBar>
              <Grid container spacing={1} md={12} direction={"row"} style={{ marginTop: 30 }}>
                <Grid item md={2} >
                  <InputField
                    label={this.state.TabSelected=='5' ? IMLocalize().orderStartDate: IMLocalize().dlvStartDate}
                    type={"date"}
                    required={false}
                    value={this.state.StartDate}
                    changeText={(data) => {
                      this.setState({
                        StartDate: data ? data.target.value : null,
                        EndDate: data ? LastDayMonth(data.target.value) : null
                      })
                    }}
                    // error={Createerrors.Date}
                    initialName={this.state.TabSelected=='5' ? IMLocalize().orderStartDate: IMLocalize().dlvStartDate}
                    errorMsg={"Please enter start date."}
                  />
                </Grid>
                <Grid item md={2} >
                  <InputField
                    label={this.state.TabSelected=='5' ? IMLocalize().orderEndDate: IMLocalize().dlvEndDate}
                    type={"date"}
                    required={false}
                    value={this.state.EndDate}
                    changeText={(data) => { this.setState({ EndDate: data ? data.target.value : null }) }}
                    // error={Createerrors.Date}
                    initialName={this.state.TabSelected=='5' ? "Order End Date": "Delivery End Date"}
                    errorMsg={"Please enter end date."}
                  />
                </Grid>
                <Grid item md={2} >
                  <Dropdown
                    label={IMLocalize().selectTeam}
                    data={this.state.TeamsLists}
                    getOptionLabel={option => (option.teamName ? option.teamName : "")}
                    getOptionSelected={(option, value) => option.id === value.id}
                    value={this.state.Team}
                    ItemChange={(event, newValue) => {
                      this.setState({
                        TeamSelected: newValue ? newValue.id : null,
                        Team: newValue ? newValue : null
                      })
                    }}
                    //error={Createerrors.TeamSelected}
                    initialName={IMLocalize().selectTeam}
                    errorMsg={"Please choose team."}
                  />
                </Grid>
                <Grid item md={2} >
                  <InputField
                    label={IMLocalize().orderNumber}
                    type={"text"}
                    required={false}
                    value={this.state.FilterOrderNo}
                    changeText={(data) => this.setState({ FilterOrderNo: data ? data.target.value : null })}
                    // error={Createerrors.Date}
                    initialName={IMLocalize().orderNumber}
                    errorMsg={"Please enter order number."}
                  />
                </Grid>
                <Grid item md={2} >
                  <InputField
                    label={IMLocalize().customerMobile}
                    type={"text"}
                    required={false}
                    value={this.state.FilterMobile}
                    changeText={(data) => this.setState({ FilterMobile: data ? data.target.value : null })}
                    // error={Createerrors.Date}
                    initialName={IMLocalize().customerMobile}
                    errorMsg={"Please enter customer mobile."}
                  />
                </Grid>
                <Grid item md={2} >
                  <InputField
                    label={IMLocalize().customerName}
                    type={"text"}
                    required={false}
                    value={this.state.FilterName}
                    changeText={(data) => this.setState({ FilterName: data ? data.target.value : null })}
                    // error={Createerrors.Date}
                    initialName={IMLocalize().customerName}
                    errorMsg={"Please enter customer name."}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1} md={12} direction={"row"} style={{ marginTop: 20 }}>
                <Grid item md={2} >
                  <InputField
                    label={IMLocalize().postalCode}
                    type={"number"}
                    required={false}
                    value={this.state.PostalCode}
                    changeText={(data) => this.setState({ PostalCode: data ? data.target.value : null })}
                    // error={Createerrors.Date}
                    initialName={IMLocalize().postalCode}
                    errorMsg={"Please enter postal code."}
                  />
                </Grid>
                <Grid item md={1} >
                  <Button
                    onClick={() => this.clearSearchClick()}
                    className={classes.buttonCancel}
                    variant="contained" color="primary">
                    {IMLocalize().clear}
                  </Button>
                </Grid>
                <Grid item md={1} >
                  <Button
                    onClick={() => this.searchClick()}
                    className={classes.buttonSuccess}
                    variant="contained" color="primary">
                    {IMLocalize().search}
                  </Button>
                </Grid>
              </Grid>
              <Grid item spacing={4} xs={12} style={{ marginTop: 30 }}>
                <MUIDataTable
                  //title="Delivery List"
                  data={this.state.FilteredList}
                  columns={columns}
                  options={{
                    textLabels: {
                      body: {
                        noMatch: IMLocalize().nomatchrecords
                      },
                      pagination: {
                        rowsPerPage: IMLocalize().rowsperpage
                      }
                    },
                    downloadOptions: {
                      filename: "Delivery_Status.csv",
                      filterOptions:{
                        useDisplayedColumnsOnly : true,
                        useDisplayedRowsOnly : true
                      }
                    },
                    customSort: (data, colIndex, order, meta) => {
                      return data.sort((a, b) => {
                        if (colIndex === 6) {
                          return (new Date(a.data[colIndex] + ' ' + a.data[4]) < new Date(b.data[colIndex] + ' ' + b.data[4]) ? -1 : 1) * (order === 'desc' ? 1 : -1);
                        } else {
                          return (a.data[colIndex] < b.data[colIndex] ? -1 : 1) * (order === 'desc' ? 1 : -1);
                        }

                      });
                    },
                    sort: true,
                    selectableRows: false,
                    selectableRowsOnClick: false,
                    responsive: true,
                    print: true,
                    download: true,
                    viewColumns: false,
                    filter: false,
                  }}
                />
              </Grid>
            </TabContext>

          </Grid>
          {this.state.TabSelected == "3" ?
            <Grid item md={12} style={{ display: "flex", justifyContent: "flex-start" }}>
              <Button
                onClick={() => this.ScheduleDeleviryClick()}
                className={classes.buttonSuccess}
                variant="contained" color="primary">
                {IMLocalize().scheduleDelivery}
          </Button>
            </Grid>
            : null}
          <AlertDialog
            Status={this.state.RestoreState}
            DialogConfirm={() => { this.RestoreConfirm() }}
            DialogCancel={() => { this.RestoreClose() }}
            Title={"Return to Marketplace/Adhoc Delivery"}
            Content={"Are you sure, you want to return the order to marketplace/adhoc delivery ?"}
          />
          <AlertDialog
            Status={this.state.CancelState}
            DialogConfirm={() => { this.CanceledConfirm() }}
            DialogCancel={() => { this.CanceledClose() }}
            Title={"Return to Progress"}
            Content={"Are you sure, you want to put back the order to progress ?"}
          />
          {this.state.EditTeamState ? <EditTeam
            Title={"Edit Team"}
            parentClass={this}
            parentState={this.state}
            Status={this.state.EditTeamState}
            ModelConfirm={this.EditedTeam}
            ModelCancel={this.CloseEditTeam}
          /> : null}
          {this.state.EditOrderState ? <EditOrder
            Title={"Edit Order"}
            parentClass={this}
            parentState={this.state}
            Status={this.state.EditOrderState}
            ModelConfirm={this.EditedOrder}
            ModelCancel={this.CloseOrderEdit}
          /> : null}
          {this.state.AssignTeamState ? <AssignTeam
            Title={"Assign Team"}
            parentClass={this}
            parentState={this.state}
            Status={this.state.AssignTeamState}
            ModelConfirm={this.AssignedTeam}
            ModelCancel={this.CloseAssign}
          /> : null}
           {this.state.ListModel ? <ViewList
            Title={"Lists"}
            parentClass={this}
            parentState={this.state}
            Status={this.state.ListModel}
            ModelCancel={this.CloseList}
          /> : null}
          <LoadingOverlay Status={this.state.isLoading} />
        </Grid>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    TeamListSuccess: state.getTeamsReducer.Success,
    TeamListFailure: state.getTeamsReducer.Failure,
    TeamLists: state.getTeamsReducer.TeamList,

    AdhocListSuccess: state.getAdhocReducer.Success,
    AdhocListFailure: state.getAdhocReducer.Failure,
    AdhocList: state.getAdhocReducer.AdhocList,

    DeleteDeliverySuccess: state.RestoreDeliveryReducer.Success,
    DeleteDeliveryFailure: state.RestoreDeliveryReducer.Failure,
    DeleteDeliveryResponse: state.RestoreDeliveryReducer.DeleteResponse,

    OrderUpdateSuccess: state.UpdateOrderStatusReducer.Success,
    OrderUpdateFailure: state.UpdateOrderStatusReducer.Failure,
    OrderUpdateResponse: state.UpdateOrderStatusReducer.UpdateResponse,

    UserDetails: state.LoginReducer.UserDetails,
    LoaderState: state.LoaderReducer.State
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTeams: (ID) => dispatch(getTeams(ID)),
    getAdhoc: (filterObj) => dispatch(getAdhoc(filterObj)),
    UpdateOrderStatus: (ID) => dispatch(UpdateOrderStatus(ID)),
    RestoreDelivery: (ID) => dispatch(RestoreDelivery(ID)),
    SendSMS: (requObj) => dispatch(SendSMS(requObj)),
    refreshReduxStates: () => dispatch(refreshReduxStates()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DeliveryStatus));
