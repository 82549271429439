import { makeStyles } from "./PackagesList";
export default makeStyles(theme => ({
    buttonSuccess:{
        backgroundColor: theme.palette.btns.primary,
        opacity:0.9,
        borderRadius:30,
        fontSize:"0.7rem",
        '&:hover': {
            backgroundColor: theme.palette.btns.secondary
        }
    },
    buttonCancel:{
        backgroundColor: theme.palette.btns.default,
        opacity:0.9,
        borderRadius:30,
        fontSize:"0.7rem",
        '&:hover': {
            backgroundColor: theme.palette.btns.gray
        }
    },
    checkBoxSize:{
        fontSize: "0.7rem"
    },
    dialogTitle: {

    },
    toolTipSpace:{
        marginRight:5
    },
    boldText1: {
        fontSize: "1rem",
        fontWeight: 600,
    },
    boldText8: {
        fontSize: "0.8rem",
        fontWeight: 600,
    },
    boldText: {
        fontSize: "0.72rem",
        fontWeight: 600,
    },
    boldText7: {
        fontSize: "0.7rem",
        fontWeight: 600,
    },
    titleBar: {
        background: 'transparent',
        height: 20
    },
    dialogWindow: {
        width: '80%'
    },
    listStyle: {
        border: "1px solid #eee",
        padding: 10,
        height: "250px",
        width: "100%"
    },
    cardStyle: {
        height: 90,
        padding: 10,
        margin: 10,
        boxShadow: "none",
        borderRadius: 10,
        backgroundColor: '#000'

    },
    paper: {
        width: "100%",
        height: 430,
        overflow: 'auto',
    },
    whiteText:{
        color: theme.palette.text.white
    },
    tabcellWidth:{
        maxWidth: 30,
        margin:10
    },
    complete:{
        backgroundColor: theme.palette.secondary.main,
    },
    progress:{
        backgroundColor: theme.palette.warning.main,
    },
    onLeave:{
        backgroundColor: theme.palette.info.main,
    },
    open:{
        backgroundColor: theme.palette.success.main,
    },
    others:{
        backgroundColor: theme.palette.primary.main,
    },
    stickyItem:{
        position: "sticky",
        left: 0,
        background: "white"
    },
    paddingLR:{
        paddingLeft:"3%",
        paddingRight:"3%"
    },
    paddingLR5:{
        paddingLeft:"5%",
        paddingRight:"5%"
    },
    pointerCourser:{
        cursor: "pointer",
    }
}));