import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider, CssBaseline, Provider, thunk, createStore, applyMiddleware, ToastContainer, toast, SimpleReactLightbox } from "./utilities/PackagesList";
import Themes from "./themes";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import { LayoutProvider } from "./context/LayoutContext";
import { UserProvider } from "./context/UserContext";
import reducer from "./redux/AllReducers";
import 'react-toastify/dist/ReactToastify.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { getSession } from "utilities/AppFunctions";
global.reduxStore = createStore(reducer, applyMiddleware(thunk));
global.CompanyID = 0;
global.gbsmsSettings = [];
global.SToken = null;
ReactDOM.render(
  <LayoutProvider>
    {/* <UserProvider> */}
    <ThemeProvider theme={Themes.default}>
      <CssBaseline />
      <Provider store={global.reduxStore}>
      <ToastContainer
          newestOnTop={true} position={"top-right"}
          autoClose={3000} hideProgressBar={true}
          closeOnClick={true}
          draggable={true} pauseOnHover={true}
        />
        {/* <SimpleReactLightbox> */}
          <App />
       {/*  </SimpleReactLightbox> */}
      </Provider>
    </ThemeProvider>
    {/*  </UserProvider> */}
  </LayoutProvider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
