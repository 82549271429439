import React, { useState, useEffect, Component } from 'react';
import {
  Grid, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle, makeStyles,
  Switch, FormControlLabel, IconButton, DeleteIcon,
  EditIcon, _, swal, toast, useSelector, useDispatch
} from './../../utilities/PackagesList';
import {
  PageTitle, CustomToolbar, AlertDialog,
  CustomModel, InputField, Dropdown,
  Buttons, LoadingOverlay
} from "./../../utilities/ComponentsList";
import { isEmptyObj } from "./../../utilities/AppFunctions";
import { AddVechile } from './../../api/Vechile';
import IMLocalize from 'utilities/IMLocalize';
const useStyles = makeStyles(theme => ({
  dialogTitle: {

  }
}))
const Create = (props) => {
  const dispatch = useDispatch();
  const createState = useSelector((state) => state.AddVechileReducer);
  const classes = useStyles();
  const [CreateValues, setCreateValues] = useState({
    VehicleType: '',
    VehicleName: '',
    VehicleSize: '',
    VechileSizeSelected: '',
    VechileTypeSelected: '',
    vehicleNumber: '',
    Active: true
  });
  /* useEffect(() => {
    setCreateValues({
      ...CreateValues,
      modelState: props.Status
    });
  }, []) */
  const [Createerrors, setCreateErrors] = useState({});
  let vechileTypes = props.parentState.VechileTypesList ? props.parentState.VechileTypesList : [];
  let vehilesize = props.parentState.VechileSizeList ? props.parentState.VechileSizeList : [];
  
  if (createState.Success && !isEmptyObj(createState.AddingResponse)) {

    toast.success('Added Successfully!', {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: false,
    });
    props.parentClass.AddedVechile()
  } else if (createState.Failure && !isEmptyObj(createState.AddingResponse)) {
    toast.error(createState.AddingResponse.message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: false,
    });
  }
  const handleChange = name => event => {
    let targetValue = event.target.value;
    setCreateValues({ ...CreateValues, [name]: targetValue });
    setCreateErrors({ ...Createerrors, [name]: targetValue ? false : true })
  };
  const submitForm = () => {
    let srrorObj = {};
    let errorStatus = false;
    Object.keys(CreateValues).map(function (k) {
      srrorObj[k] = CreateValues[k] ? false : true;
      errorStatus = errorStatus ? true : !CreateValues[k];
    })

    console.log(JSON.stringify(srrorObj))
    setCreateErrors(srrorObj);
    if (!errorStatus) {
      let addObject = {
        vehicleTypeId: CreateValues.VechileTypeSelected,
        name: CreateValues.VehicleName,
        vehicleSizeId: CreateValues.VechileSizeSelected,
        vehicleNumber: CreateValues.vehicleNumber,
        //isActive : CreateValues.Active,
      }

      dispatch(AddVechile(addObject));
    }


  }

  return (
    <Dialog
      open={props.Status}
      //onClose={()=>this.closeDialog()}
      fullWidth
      maxWidth="sm"
      disableBackdropClick={false}
      disableEscapeKeyDown={false}
    >
      <DialogTitle className={classes.dialogTitle} id="dialog-title">{props.Title}</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={4}>
          <Grid item md={12}>
            <Grid
              container
              spacing={6}
              direction="column"
            >
              <Grid item md={12} >
                <Dropdown
                  label={IMLocalize().vehicletype}
                  data={vechileTypes}
                  getOptionLabel={option => (option.vehicelType ? option.vehicelType : "")}
                  getOptionSelected={(option, value) => option.id === value.id}
                  value={CreateValues.VehicleType}
                  ItemChange={(event, newValue) => {
                    setCreateValues({
                      ...CreateValues,
                      VechileTypeSelected: newValue ? newValue.id : null,
                      VehicleType: newValue ? newValue : null
                    });
                    setCreateErrors({
                      ...Createerrors,
                      VechileTypeSelected: newValue ? false : true,
                      VehicleType: newValue ? false : true
                    })
                  }}
                  error={Createerrors.VechileTypeSelected}
                  initialName={IMLocalize().vehicletype}
                  errorMsg={"Please choose vehicle type."}
                />
              </Grid>
              <Grid item md={12}>
                <InputField
                  label={IMLocalize().vehicleName}
                  type={"text"}
                  required={true}
                  value={CreateValues.VehicleName}
                  changeText={handleChange('VehicleName')}
                  error={Createerrors.VehicleName}
                  initialName={IMLocalize().vehicleName}
                  errorMsg={"Please enter vechile name."}
                />
              </Grid>
              <Grid item md={12}>
                <InputField
                  label={IMLocalize().vehicleNumber}
                  type={"text"}
                  required={true}
                  value={CreateValues.vehicleNumber}
                  changeText={handleChange('vehicleNumber')}
                  error={Createerrors.vehicleNumber}
                  initialName={IMLocalize().vehicleNumber}
                  errorMsg={"Please enter vechile number."}
                />
              </Grid>
              <Grid item md={12}>
                <Dropdown
                  label={IMLocalize().vehicleSize}
                  data={vehilesize}
                  getOptionLabel={option => (option.vehicelSize ? option.vehicelSize : "")}
                  getOptionSelected={(option, value) => option.id === value.id}
                  value={CreateValues.VehicleSize}
                  ItemChange={(event, newValue) => {
                    setCreateValues({
                      ...CreateValues,
                      VechileSizeSelected: newValue ? newValue.id : null,
                      VehicleSize: newValue ? newValue : null
                    });
                    setCreateErrors({
                      ...Createerrors,
                      VechileSizeSelected: newValue ? false : true,
                      VehicleSize: newValue ? false : true
                    })
                  }}
                  error={Createerrors.VechileSizeSelected}
                  initialName={IMLocalize().vehicleSize}
                  errorMsg={"Please choose vehicle size."}
                />
              </Grid>
              {/* <Grid item md={12} >
              <FormControlLabel
                value={CreateValues.Active}
                control={
                  <Switch
                    size="medium"
                    checked={CreateValues.Active}
                    onChange={handleChange('Active')}
                    inputProps={{ 'aria-label': 'primary' }}
                    color="primary" />
                }
                label="Activate"
                fullWidth={true}
                labelPlacement="start"
              />
            </Grid> */}
            </Grid>

          </Grid>
          <Buttons
            close={() => props.parentClass.CloseCreate()}
            closeText={IMLocalize().calcel}
            save={() => submitForm()}
            saveText={IMLocalize().submit}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default Create;